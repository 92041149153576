import React, { useEffect, useState } from "react";
import Sidebar from "../SideBar";
import {
  AppContainer,
  AppHeader,
  LayoutContainer,
  MenuBurgerContainer,
} from "./styled";
import { useAppSelector } from "../../../redux/reduxHooks";
import { selectLoggedIn } from "../../../redux/slices/authSlice";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs";
import Menu from "../../../Images/icons/menu.svg";
import VerticalModal from "../VerticalModal";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const loggedIn = useAppSelector(selectLoggedIn);
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1000) {
        setShowMenu(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (
      !loggedIn ||
      location.pathname === "/login" ||
      location.pathname.includes("/register/")
    ) {
      setShowSidebar(false);
    } else {
      setShowSidebar(true);
    }
  }, [loggedIn, location.pathname]);

  return (
    <LayoutContainer>
      {showSidebar ? <Sidebar /> : null}
      <AppContainer showSideBar={showSidebar}>
        {showSidebar ? (
          <AppHeader>
            <Breadcrumbs />

            <MenuBurgerContainer
              onClick={() => {
                setShowMenu(true);
              }}
            >
              <img src={Menu} alt="menu" width={30} height={30} />
            </MenuBurgerContainer>
          </AppHeader>
        ) : null}

        {children}
      </AppContainer>
      <VerticalModal open={showMenu} onClose={() => setShowMenu(false)}>
        {showSidebar ? <Sidebar
          style={{
            display: "flex",
            width: "100%",
            borderRight: "none",
            paddingInline: "0px",
            position: "static",
          }}
        /> : null }
      </VerticalModal>
    </LayoutContainer>
  );
};

export default Layout;
