import { useMutation, useQuery } from "@tanstack/react-query";
import { apiClient } from "./apiClient";
import {
  APIResponse,
  ForgotPasswordParams,
  ResetPasswordParams,
  LoginParams,
  LoginResponse,
  RegisterUserParams,
  RegisterUserResponse,
  ValidateOTPParams,
  VerifyEmailResponse,
} from "../types/apisTypes";
import { USER } from "../types/auth";
import { Country } from "../types";

export const useLogin = (params: LoginParams) => {
  return useMutation<LoginResponse, Error>({
    mutationFn: async () => {
      const response = await apiClient.post("/login", params);
      return response.data;
    },
  });
};

export const useLogout = () => {
  return useMutation<void, Error>({
    mutationFn: async () => {
      const response= await apiClient.get("/logout");
      return response.data;
    },
  });
};

export const useForgotPassword = (params: ForgotPasswordParams) => {
  return useMutation<string, Error>({
    mutationFn: async () => {
      const response = await apiClient.post("/forgot_password", params);
      return response.data;
    },
  });
};

export const useResetPassword = () => {
  return useMutation<string, Error, ResetPasswordParams>({
    mutationFn: async (params: ResetPasswordParams) => {
      const response = await apiClient.post("/reset_password", params);
      return response.data;
    },
  });
};

export const useRegisterUser = (params: RegisterUserParams) => {
  return useMutation<RegisterUserResponse, Error>({
    mutationFn: async () => {
      const response = await apiClient.post("/register", {
        ...params,
      });
      return response.data;
    },
  });
};

export const useVerifyEmail = (token: string) => {
  return useMutation<VerifyEmailResponse, Error>({
    mutationFn: async () => {
      const response = await apiClient.get(`/verify_email?token=${token}`);
      return response.data;
    },
  });
};

export const useResendVerificationEmail = (email: string) => {
  return useMutation<VerifyEmailResponse, Error>({
    mutationFn: async () => {
      const response = await apiClient.post("/resend_email", {
        email,
        type: "verify_email",
      });
      return response.data;
    },
  });
};

export const useGetUserData = (enabled: boolean) => {
  return useQuery<USER, Error>({
    queryFn: async () => {
      const response = await apiClient.get("/user");
      return response.data.user;
    },
    queryKey: ["user"],
    enabled,
  });
};

export const useValidateOTP = ({ otp }: ValidateOTPParams) => {
  return useMutation<APIResponse, Error>({
    mutationFn: async () => {
      const response = await apiClient.post("/auth-otp", { otp });
      return response.data;
    },
  });
};

export const useGetCountries = () => {
  return useQuery<Country[], Error>({
    queryFn: async () => {
      const response = await apiClient.get("/country");
      return response.data.countries;
    },
    queryKey: ["countries"],
    retry: false,
  });
};
