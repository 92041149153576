import { useNavigate } from "react-router-dom";
import { ButtonSizes, ButtonVariants } from "../../../../../constants/styles";
import { NextButton } from "../styled";
import InputField from "../../../../shared/InputField";
import DropdownList from "../../../../shared/DropdownList";
import {
  RegistrationSteps,
  organizationRoleOptions,
  organizationSizeOptions,
} from "../../../../../constants/registration";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/reduxHooks";
import { useEffect, useState } from "react";
import {
  DropdownListOption,
  SnackbarTypes,
} from "../../../../../types/componentsProps";
import { useCreateOrganization } from "../../../../../queries/companies";
import { useSnackbar } from "../../../../../hooks/useSnackbar";
import { storeLoggedIn } from "../../../../../redux/slices/authSlice";
import { useGetCountries } from "../../../../../queries/auth";
import { createCountriesDropdownOptions } from "../../../../../redux/slices/appState";

const OrganizationDetailsForm = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { countriesOptions } = useAppSelector((state) => state.appState);

  const { data: countries, error: countriesError } = useGetCountries();
  const [formData, setFormData] = useState({
    name: "",
    country:
      countriesOptions.length > 0
        ? countriesOptions[0]
        : { label: "", value: "" },
    size: organizationSizeOptions[0],
    role: organizationRoleOptions[0],
  });

  const [error, setError] = useState({
    name: "",
  });
  const { mutateAsync: createOrganization, isPending, isError } = useCreateOrganization();

  const setFormDataPoint = (
    key: string,
    value: string | boolean | DropdownListOption
  ) => {
    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (countriesOptions.length > 0) {
      setFormDataPoint(
        "country",
        countriesOptions.filter((country) => country.label === "Egypt")[0]
      );
    }
  }, [countriesOptions]);

  useEffect(() => {
    if (countries) {
      dispatch(createCountriesDropdownOptions(countries));
    }
  }, [countries]);

  const validateForm = () => {
    let isValid = true;

    if (!formData.name) {
      setError((prev) => ({ ...prev, name: "Organization name is required" }));
      isValid = false;
    }

    return isValid;
  };

  const handleNext = async () => {
    if (validateForm()) {
      try {
        await createOrganization({
          name: formData.name,
          country: formData.country.value,
          size_min: formData.size.value.min,
          size_max: formData.size.value.max,
        });
        // navigate(`/register/${RegistrationSteps.solutions}`, {});
        dispatch(storeLoggedIn(true));
      } catch (e: any) {
        if (e.response.status === 401) {
          showSnackbar({
            open: true,
            title: "Error creating organization",
            description: e.response.data.message,
            type: SnackbarTypes.ERROR,
            actionBtnText: "Login",
            actionBtnCallback: () => {
              navigate("/login");
            },
          });
        } else {
          showSnackbar({
            open: true,
            title: "Error creating organization",
            description:
              "An error occurred while creating the organization. Please try again.",
            type: SnackbarTypes.ERROR,
          });
        }
      }
    }
  };

  return (
    <>
      <InputField
        label="Organization name"
        containerStyle={{ width: "100%" }}
        value={formData.name}
        onChange={(e) => setFormDataPoint("name", e.target.value)}
        error={error.name}
        onFocus={() => setError((prev) => ({ ...prev, name: "" }))}
      />
      <DropdownList
        label="Headquarters Country"
        options={countriesOptions}
        selectedOption={formData.country}
        setSelectedOption={(option) => setFormDataPoint("country", option)}
      />
      <DropdownList
        label="Organization size"
        options={organizationSizeOptions}
        selectedOption={formData.size}
        setSelectedOption={(option) => setFormDataPoint("size", option)}
      />

      <NextButton
        text="Next"
        variant={ButtonVariants.primary}
        size={ButtonSizes.lg}
        onClick={handleNext}
        loading={isPending}
        />
    </>
  );
};

export default OrganizationDetailsForm;
