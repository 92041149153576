import axios, { AxiosError } from "axios";
import { DashboardBanners } from "../constants/banners";

const refreshTokenFn = async () => {
  try {
    const response = await apiClient.get("/refresh");

    const token = response.data.access_token;
    if (!token) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.setItem(
        "banner",
        JSON.stringify(DashboardBanners.map((banner) => banner.id))
      );
    }

    localStorage.setItem("token", JSON.stringify(token));

    return token;
  } catch (error) {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.setItem("loggedIn", "false");
    localStorage.setItem(
      "banner",
      JSON.stringify(DashboardBanners.map((banner) => banner.id))
    );
  }
};

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
});

apiClient.interceptors.response.use(
  function (response) {
    return response;
  },
  async (error: AxiosError) => {
    const originalRequest = error.config;
    const status = error.response?.status;
    if (status === 403) {
      window.location.href = "/404";
    } else if (status === 401) {
      
      // TODO: Fix refresh token endpoint

      {
        /*const token = await refreshTokenFn();
      if (token && originalRequest) {
        apiClient.defaults.headers.common["Authorization"] = token;

        return apiClient(originalRequest);
      } */
      }

      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.setItem("loggedIn", "false");
      localStorage.setItem(
        "banner",
        JSON.stringify(DashboardBanners.map((banner) => banner.id))
      );

      if (window.location.pathname !== "/login") {
        window.location.href = "/login";
      }
    }

    return Promise.reject(error);
  }
);
