import React, { useEffect, useState } from "react";
import {
  ManageHeader,
  PageSubtitle,
  PageTitle,
  HeaderFirstRow,
  ManageContentContainer,
  TitleContainer,
  ContentContainer,
  FiltersContainer,
  TableContainer,
} from "./styled";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
  LoaderSizes,
} from "../../../constants/styles";
import Button from "../../shared/Button";
import PlusIcon from "../../../Images/icons/plus.svg";
import EmptyState from "./EmptyState";
import { useNavigate } from "react-router-dom";
import Badge from "../../shared/Badge";
import {
  BadgeTypes,
  DropdownListOption,
  SnackbarTypes,
} from "../../../types/componentsProps";
import EmployeeTable from "./EmployeeTable";
import SearchInput from "../../shared/SearchInput";
import DropdownList from "../../shared/DropdownList";
import { useGetEmployees } from "../../../queries/people";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { AxiosError } from "axios";
import { useAppDispatch, useAppSelector } from "../../../redux/reduxHooks";
import { setEmployeesTableVariables } from "../../../redux/slices/peopleSlice";
import Loader from "../../shared/Loader";
import { ALL_STATUS_OPTIONS } from "../../../constants/dropDownLists";
import { CompanyStatus } from "../../../types/companies";
import { useDebounce } from "../../../hooks/useDebounce";

const Manage: React.FC = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  const {
    sortKey,
    sortDirection,
    search,
    selectedStatus,
    currentPage,
    filteredEmployees,
    itemsPerPage,
    totalRecords,
  } = useAppSelector((state) => state.people.employees_table_variables);
  const activeCompany = useAppSelector(
    (state) => state.companies.activeCompany
  );
  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = useState<string>(search);
  const debounceValue = useDebounce(searchValue);
  const {
    data: employeesData,
    error,
    isPending,
    isFetched,
  } = useGetEmployees({
    company_id: activeCompany?.id.toString() ?? "",
    status:
      selectedStatus.label === "All Statuses"
        ? undefined
        : selectedStatus.label,
    name: search,
    sort: sortKey,
    sort_dir: sortDirection,
    page: currentPage,
    limit: itemsPerPage,
  });

  useEffect(() => {
    if (employeesData?.employees) {
      dispatch(
        setEmployeesTableVariables({
          filteredEmployees: employeesData.employees,
          currentPage: employeesData._metadata.page,
          totalRecords: employeesData._metadata.total_records,
          totalPages: employeesData._metadata.last_page,
        })
      );
    }
  }, [employeesData]);

  useEffect(() => {
    if (error) {
      dispatch(setEmployeesTableVariables({ filteredEmployees: null }));

      showSnackbar({
        type: SnackbarTypes.ERROR,
        open: true,
        title: "Something went wrong while fetching employees.",
        description: "Please try again later.",
      });
    }
  }, [error]);

  useEffect(() => {
    dispatch(
      setEmployeesTableVariables({
        search: debounceValue,
        currentPage: 1
      })
    );
  }, [debounceValue]);

  const checkCompanyStatus = () => {
    if (activeCompany?.company_status !== CompanyStatus.PENDING) {
      navigate("/people/new-employee");
      return;
    } else {
      showSnackbar({
        type: SnackbarTypes.ERROR,
        open: true,
        title: "Can't add an employee, your company is still pending.",
        description: "Please try again later.",
      });
    }
  };

  useEffect(() => {
    return function() {
      dispatch(setEmployeesTableVariables({
        selectedStatus: ALL_STATUS_OPTIONS[0],
        search: "",
        sortKey: "created_at",
        sortDirection: "ASC",
        currentPage: 1,
      }))
    }
  }, [])

  const renderEmployees = () => {
    return (
      <ContentContainer>
        <FiltersContainer>
          <SearchInput
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Search for people"
            containerStyle={{ width: "36.5%" }}
          />
          <DropdownList
            options={ALL_STATUS_OPTIONS}
            selectedOption={selectedStatus}
            setSelectedOption={(status: DropdownListOption) =>
              dispatch(
                setEmployeesTableVariables({
                  selectedStatus: status,
                  currentPage: 1,
                })
              )
            }
            containerStyle={{ width: "21.3%" }}
            style={{ height: "48px", marginTop: "0px" }}
          />
        </FiltersContainer>
        <TableContainer>
          <EmployeeTable />
        </TableContainer>
      </ContentContainer>
    );
  };

  return (
    <>
      <ManageHeader>
        <HeaderFirstRow>
          <TitleContainer>
            <PageTitle size={FontSizes.SM} weight={FontWeights.SEMIBOLD}>
              Manage People
            </PageTitle>
            {totalRecords > 0 ? (
              <Badge
                text={totalRecords.toString()}
                color={BadgeTypes.grayBlue}
              />
            ) : null}
          </TitleContainer>

          {activeCompany ? (
            <Button
              text="Add Employee"
              variant={ButtonVariants.primary}
              size={ButtonSizes.md}
              iconLeading={
                <img src={PlusIcon} alt="plus" width={20} height={20} />
              }
              onClick={checkCompanyStatus}
            />
          ) : null}
        </HeaderFirstRow>
        <PageSubtitle size={FontSizes.TX_MD} weight={FontWeights.REGULAR}>
          Effortlessly monitor and manage your employees’ information.
        </PageSubtitle>
      </ManageHeader>

      <ManageContentContainer>
        {!activeCompany ? (
          <EmptyState />
        ) : isPending ? (
          <Loader size={LoaderSizes.lg} />
        ) : (!filteredEmployees || (filteredEmployees.length == 0 && isFetched)) &&
          selectedStatus === ALL_STATUS_OPTIONS[0] && search === "" ? (
          <EmptyState checkStatus={checkCompanyStatus} />
        ) : (
          renderEmployees()
        )}
      </ManageContentContainer>
    </>
  );
};

export default Manage;
