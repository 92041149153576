import { useParams } from "react-router-dom";
import {
  Card,
  CardRow,
  CardRowTitle,
  CardRowValue,
  CardTitle,
} from "../../../shared/Card";
import {
  CardsColumn,
  DetailsTabContainer,
  PaymentTrackerContainer,
  StepIcon,
  StepIconWrapper,
  StepSubtitle,
  StepTitle,
  StepperConnector,
} from "./styled";
import { FontSizes, FontWeights } from "../../../../constants/styles";
import { currencyFormatter } from "../../../../utilities/currencyFormatter";
import Badge from "../../../shared/Badge";
import { PAYMENT_STATUS_BADGE_COLOR } from "../../../../types/payments";
import ActiveIcon from "../../../../Images/icons/activeStepIcon.svg";
import InactiveIcon from "../../../../Images/icons/InactiveStepperStepGrey.svg";
import CompletedIcon from "../../../../Images/icons/completedStepIcon.svg";
import moment from "moment";
import { useGetInternalBankAccount, useGetPaymentDetails } from "../../../../queries/payment";
import { useEffect } from "react";
import { SnackbarTypes } from "../../../../types/componentsProps";
import { useSnackbar } from "../../../../hooks/useSnackbar";

const DetailsTab = () => {
  const { payment_id } = useParams();
  const { showSnackbar } = useSnackbar();

  const { data: payment } = useGetPaymentDetails(payment_id);
  const { data: bank, error: bankError } = useGetInternalBankAccount(payment?.currency);

  useEffect(() => {
    if (bankError) {
      showSnackbar({
        open: true,
        type: SnackbarTypes.ERROR,
        title: "Something went wrong while fetching internal bank account",
        description: "Please try again later",
      });
    }
  }, [bankError]);

  const renderPaymentTracker = () => {
    return (
      <Card>
        <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Payment Tracker
        </CardTitle>
        <PaymentTrackerContainer>
          <StepIconWrapper>
            <StepIcon
              src={CompletedIcon}
              width={32}
              height={32}
              alt="step-icon"
              isCurrent={false}
            />
            <StepperConnector isComplete={true} isLast={false} />
          </StepIconWrapper>

          <div>
            <StepTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Payment Initiated
            </StepTitle>
            <StepSubtitle size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {moment(payment?.created_at).format("DD MMM, YYYY")}
            </StepSubtitle>
          </div>
        </PaymentTrackerContainer>
        {/* //TODO replace created at with the right data point */}
        {payment?.created_at ? (
          <PaymentTrackerContainer>
            <StepIconWrapper>
              <StepIcon
                src={CompletedIcon}
                width={32}
                height={32}
                alt="step-icon"
                isCurrent={false}
              />
              <StepperConnector isComplete={true} isLast={true} />
            </StepIconWrapper>

            <div>
              <StepTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Payment received
              </StepTitle>
              <StepSubtitle size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
                {moment(payment?.created_at).format("DD MMM, YYYY")}
              </StepSubtitle>
            </div>
          </PaymentTrackerContainer>
        ) : (
          <>
            <PaymentTrackerContainer>
              <StepIconWrapper>
                <StepIcon
                  src={ActiveIcon}
                  width={32}
                  height={32}
                  alt="step-icon"
                  isCurrent={false}
                />
                <StepperConnector isComplete={false} isLast={false} />
              </StepIconWrapper>

              <div>
                <StepTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                  Waiting for your payment
                </StepTitle>
                <StepSubtitle
                  size={FontSizes.TX_SM}
                  weight={FontWeights.REGULAR}
                >
                  This may take 2-3 days
                </StepSubtitle>
              </div>
            </PaymentTrackerContainer>
            <PaymentTrackerContainer>
              <StepIconWrapper>
                <StepIcon
                  src={InactiveIcon}
                  width={32}
                  height={32}
                  alt="step-icon"
                  isCurrent={false}
                />
                <StepperConnector isComplete={false} isLast={true} />
              </StepIconWrapper>

              <div>
                <StepTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                  Payment received
                </StepTitle>
                <StepSubtitle
                  size={FontSizes.TX_SM}
                  weight={FontWeights.REGULAR}
                >
                  We will process your payment as soon as we receive it.
                </StepSubtitle>
              </div>
            </PaymentTrackerContainer>
          </>
        )}
      </Card>
    );
  };

  return (
    <DetailsTabContainer>
      <CardsColumn>
        <Card>
          <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            Payment Details
          </CardTitle>
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Amount
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {currencyFormatter(payment?.value, payment?.currency)}
            </CardRowValue>
          </CardRow>
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Balance Applied
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {currencyFormatter(payment?.balance?.diff, payment?.currency)}
            </CardRowValue>
          </CardRow>
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Status
            </CardRowTitle>
            <Badge
              text={payment?.status || ""}
              dot
              color={PAYMENT_STATUS_BADGE_COLOR[payment?.status || ""]}
            />
          </CardRow>
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Invoice(s)
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {payment?.invoices.length}
            </CardRowValue>
          </CardRow>
        </Card>

        <Card>
          <CardRowTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            Ovarc bank account details
          </CardRowTitle>
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Currency
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
              {payment?.internal_bank_account?.currency}
            </CardRowValue>
          </CardRow>

          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Bank Name
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
              {payment?.internal_bank_account?.bank_name}
            </CardRowValue>
          </CardRow>

          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Account Number
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
              {payment?.internal_bank_account?.account_number}
            </CardRowValue>
          </CardRow>

          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Swift Code
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
              {payment?.internal_bank_account?.swift_code}
            </CardRowValue>
          </CardRow>

          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              IBAN
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
              {payment?.internal_bank_account?.iban}
            </CardRowValue>
          </CardRow>
        </Card>
      </CardsColumn>

      <CardsColumn>{renderPaymentTracker()}</CardsColumn>
    </DetailsTabContainer>
  );
};

export default DetailsTab;
