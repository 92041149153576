import { DashboardBanner } from "../types";
import BenefitsImg from "../Images/benifitsBannerImg.svg";
import BankImg from "../Images/bankBannerImg.svg";
import WorkplaceImg from "../Images/workplaceBannerImg.svg";
import EquityImg from "../Images/equityBannerImg.svg";
import { Colors } from "./styles";

export const DashboardBanners: DashboardBanner[] = [
  {
    id: "1",
    title: "Setup your Benefits & Perks",
    description: "Browse our wide range of benefits in Benefits Hub",
    btnText: "Benefits Hub",
    onClick: () => console.log("Benefits Hub"),
    color: Colors.Blue_light_50,
    image: <img src={BenefitsImg} width={156} height={107} />,
  },

  {
    id: "2",
    title: "Setup your Bank Account",
    description: "Maui he tatai hei whakamanahia i na ake pukenga...",
    btnText: "Payments",
    onClick: () => console.log("Payments"),
    color: Colors.Warning_25,
    image: <img src={BankImg} width={156} height={107} />,
  },

  {
    id: "3",
    title: "Tailor Your Workspace",
    description: "Maui he tatai hei whakamanahia i na ake pukenga...",
    btnText: "Assets Hub",
    onClick: () => console.log("Assets Hub"),
    color: Colors.Error_25,
    image: <img src={WorkplaceImg} width={156} height={107} />,
  },

  {
    id: "4",
    title: "Setup Company Equity",
    description: "Maui he tatai hei whakamanahia i na ake pukenga...",
    btnText: "CTA HERE",
    onClick: () => console.log("CTA HERE"),
    color: Colors.Green_light_25,
    image: <img src={EquityImg} width={156} height={107} />,
  },
];
