import {
  LogOutBtn,
  SideBarEntryContainer,
  SideBarEntryIcon,
  SideBarFooter,
  SidebarContainer,
  UserDataContainer,
  UserEmail,
  UserName,
  SideBarEntryTitle,
  SupportEntriesContainer,
  BottomSection,
  SideBarHeader,
  NotificationBtn,
  EntryRightSide,
  Chevron,
  ChildContainer,
  AppLogo,
} from "./styled";
import LogOut from "../../../Images/icons/log-out.svg";
import { FontSizes, FontWeights } from "../../../constants/styles";
import { useAppDispatch, useAppSelector } from "../../../redux/reduxHooks";
import {
  removeUser,
  selectToken,
  selectUser,
  storeUserData,
} from "../../../redux/slices/authSlice";
import {
  ADMIN_SIDE_BAR_ENTRIES,
  HOME_SCREEN,
  SIDE_BAR_ENTRIES,
  SIDE_BAR_SUPPORT_ENTRIES,
} from "../../../constants/routesNames";
import Logo from "../../../Images/icons/LogoColored.svg";
import NotificationIcon from "../../../Images/icons/notificationIcon.svg";
import { SideBarEntry } from "../../../types";
import ChevronDown from "../../../Images/icons/chevronDown.svg";
import ChevronUp from "../../../Images/icons/chevronUp.svg";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetUserData, useLogout } from "../../../queries/auth";
import DropdownList from "../DropdownList";
import {
  DropdownListOption,
  SnackbarTypes,
} from "../../../types/componentsProps";
import { useGetUserCompanies } from "../../../queries/companies";
import { createCompaniesDropdownOptions, setActiveCompany } from "../../../redux/slices/companiesSlice";
import InitialsProfilePic from "../InitialsProfilePic";
import { useSnackbar } from "../../../hooks/useSnackbar";

const Sidebar = ({ style }: { style?: React.CSSProperties }) => {
  const { showSnackbar } = useSnackbar();
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [openTab, setOpenTab] = useState<string | null>(null);
  const [expanded, setExpanded] = useState<string | null>(null);
  const token = useAppSelector(selectToken);
  const { userCompaniesOptions, activeCompany } = useAppSelector(
    (state) => state.companies
  );
  const { data: companies, error: companiesError } = useGetUserCompanies(
    !!token && user?.type !== "ovarc_admin"
  );
  const { mutateAsync: logout } = useLogout();

  const { data: userData, error: userError } = useGetUserData(!!token);

  const [selectedCompany, setSelectedCompany] = useState<DropdownListOption>(
    userCompaniesOptions?.[0]
  );

  const onTokenExpired = async () => {
    await logout();
    dispatch(removeUser());
  };
  useEffect(() => {
    if (userCompaniesOptions) {
      setSelectedCompany(userCompaniesOptions[0]);
    }
  }, [userCompaniesOptions]);

  useEffect(() => {
    if(selectedCompany) {
      const filteredCompany = companies?.find((company) => company.name === selectedCompany.label);
      if(filteredCompany) {
        dispatch(setActiveCompany(filteredCompany))
      }
    }
  }, [selectedCompany])

  useEffect(() => {
    if (companies) {
      dispatch(createCompaniesDropdownOptions(companies));
    }
  }, [companies]);

  useEffect(() => {
    if (userData) {
      dispatch(storeUserData(userData));
    }
  }, [userData]);

  const entries = useMemo(() => {
    return userData?.type === "ovarc_admin"
      ? ADMIN_SIDE_BAR_ENTRIES
      : SIDE_BAR_ENTRIES;
  }, [userData]);

  useEffect(() => {
    if (companiesError || userError) {
  
        showSnackbar({
          open: true,
          type: SnackbarTypes.ERROR,
          title: "Something went wrong",
          description:
            "An error occurred while fetching the data. Please try again later.",
        });
      
    }
  }, [companiesError, userError]);

  const checkActivePath = (
    text: string,
    path: string,
    children?: {
      text: string;
      path: string;
      extraPaths?: string[];
    }[],
    extraPaths?: string[]
  ) => {
    const isChildActive =
      children &&
      children.some(
        (child) =>
          location.pathname === child.path ||
          (child.extraPaths &&
            child.extraPaths.some((p) => location.pathname.includes(p)))
      );

    const isParentActive =
      location.pathname === path ||
      (extraPaths && extraPaths.some((p) => location.pathname.includes(p)));
    if (isChildActive || isParentActive) {
      setOpenTab(path);
      children && setExpanded(text);
    }
  };
  const Entry = ({ text, icon }: { text: string; icon: string }) => {
    return (
      <EntryRightSide>
        <SideBarEntryIcon src={icon} width={24} height={24} />
        <SideBarEntryTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          {text}
        </SideBarEntryTitle>
      </EntryRightSide>
    );
  };

  const EntryChild = ({
    text,
    path,
    extraPaths,
  }: {
    text: string;
    path: string;
    extraPaths?: string[];
  }) => {
    const isActive =
      location.pathname === path ||
      (extraPaths && extraPaths.some((p) => location.pathname.includes(p)));
    const isDisabled = !activeCompany && text !== "Manage";

    return (
      <ChildContainer
        to={isDisabled ? "#" : path}
        active={!!isActive}
        disabled={isDisabled}
      >
        <SideBarEntryTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          {text}
        </SideBarEntryTitle>
      </ChildContainer>
    );
  };

  const SideBarEntry = ({
    text,
    icon,
    path,
    children,
    extraPaths,
  }: SideBarEntry) => {
    checkActivePath(text, path, children, extraPaths);
    const isActive =
      location.pathname === path ||
      (extraPaths && extraPaths.some((p) => location.pathname.includes(p)));
    const isExpanded = expanded === text;
    const isDisabled =
      (activeCompany || userData?.type === "ovarc_admin") ? (
        text !== "Overview" &&
        text !== "People" &&
        text !== "Payments" &&
        text !== "Payroll"
      ) : true; 
    return (
      <>
        <SideBarEntryContainer
          to={!isDisabled && path ? path : "#"}
          active={isActive || false}
          onClick={(e) => {
            if (!children) {
              navigate(path);
            }
          }}
          disabled={isDisabled}
        >
          <Entry text={text} icon={icon} />

          {children ? (
            isExpanded ? (
              <Chevron
                src={ChevronUp}
                alt="arrow"
                width={20}
                height={20}
                onClick={(e) => {
                  setExpanded(null);
                }}
              />
            ) : (
              <Chevron
                src={ChevronDown}
                alt="arrow"
                width={20}
                height={20}
                onClick={(e) => {
                  setExpanded(text);
                }}
              />
            )
          ) : null}
        </SideBarEntryContainer>
        {expanded === text && children
          ? children.map((child) => {
              return (
                <EntryChild
                  text={child.text}
                  path={child.path}
                  extraPaths={child.extraPaths}
                />
              );
            })
          : null}
      </>
    );
  };

  return (
    <SidebarContainer style={style}>
      <SideBarHeader>
        <AppLogo
          src={Logo}
          alt="logo"
          width={104}
          height={32}
          onClick={() => {
            navigate(HOME_SCREEN);
          }}
        />
        <NotificationBtn
          src={NotificationIcon}
          alt="notification"
          width={36}
          height={36}
        />
      </SideBarHeader>

      {userCompaniesOptions.length > 0 && user?.type !== "ovarc_admin" ? (
        <DropdownList
          label=""
          options={userCompaniesOptions}
          selectedOption={selectedCompany}
          setSelectedOption={(company: DropdownListOption) => {
            setSelectedCompany(company);
          }}
        />
      ) : null}

      <div>
        {entries.map((entry) => (
          <SideBarEntry key={entry.text} {...entry} />
        ))}
      </div>

      <BottomSection>
        {/* <SupportEntriesContainer>
          {SIDE_BAR_SUPPORT_ENTRIES.map((entry) => (
            <SideBarEntry key={entry.text} {...entry} />
          ))}
        </SupportEntriesContainer> */}

        <SideBarFooter>
          <UserDataContainer>
            <InitialsProfilePic
              fullName={`${user?.first_name} ${user?.last_name}`}
            />
            <div style={{ marginInlineStart: "1rem", maxWidth: "70%" }}>
              <UserName size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                {" "}
                {user?.first_name} {user?.last_name}
              </UserName>
              <UserEmail size={FontSizes.TX_MD} weight={FontWeights.REGULAR}>
                {" "}
                {user?.email}
              </UserEmail>
            </div>
          </UserDataContainer>
          <LogOutBtn
            src={LogOut}
            alt="logout"
            width={20}
            height={20}
            onClick={() => {
              dispatch(removeUser());
            }}
          />
        </SideBarFooter>
      </BottomSection>
    </SidebarContainer>
  );
};

export default Sidebar;
