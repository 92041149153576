import { useEffect } from "react";
import PeopleEmptyState from "../../PeopleEmptyState";
import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
  TableRowDynamic,
  TableHeadWithIcon,
} from "../../../shared/Table/styled";
import Typography from "../../../shared/Typography";
import {
  PayslipPeriod,
  PayslipsContainer,
  PayslipsHeader,
  PayslipsText,
  PayslipsTitle,
} from "./styled";
import { FontSizes, FontWeights } from "../../../../constants/styles";
import moment from "moment";
import { currencyFormatter } from "../../../../utilities/currencyFormatter";
import ActionsDropDown from "../../../shared/ActionsDropDown";
import { useNavigate, useParams } from "react-router-dom";
import { useGetEmployeePayslips } from "../../../../queries/people";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { SnackbarTypes } from "../../../../types/componentsProps";
import { useAppDispatch, useAppSelector } from "../../../../redux/reduxHooks";
import TablePagination from "../../../shared/TablePagination";
import { updateEmployeePayslipsTableVariables } from "../../../../redux/slices/peopleSlice";
import { Sort } from "../../../shared/SortIcon";
const Payslips = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { employeePayslipsTableVariables } = useAppSelector(
    (state) => state.people
  );
  const { data, error } = useGetEmployeePayslips(id, {
    sort: employeePayslipsTableVariables.sort,
    sort_dir: employeePayslipsTableVariables.sort_dir,
    page: employeePayslipsTableVariables.page,
    limit: employeePayslipsTableVariables.limit,
  });
  const navigate = useNavigate();

  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    if (error) {
      showSnackbar({
        open: true,
        type: SnackbarTypes.ERROR,
        title: "Something went wrong",
        description: "Failed to fetch employee payslips",
      });
    }
  }, [error]);

  const changeSortDirection = () => {
    dispatch(
      updateEmployeePayslipsTableVariables({
        sort_dir:
          employeePayslipsTableVariables.sort_dir === "ASC" ? "DESC" : "ASC",
      })
    );
  };
  const renderPayslipsRows = () => {
    return data?.payslips?.map((payslip, index) => {
      return (
        <TableRow key={index}>
          <TableCell>
            <PayslipPeriod size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
              {moment(payslip.pay_period_start).format("DD MMM, YYYY")} -{" "}
              {moment(payslip.pay_period_end).format("DD MMM, YYYY")}
            </PayslipPeriod>
          </TableCell>

          <TableCell>
            <PayslipsText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {moment(payslip.payment_date).format("DD MMM, YYYY")}
            </PayslipsText>
          </TableCell>
          <TableCell>
            <PayslipsText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {currencyFormatter(
                parseFloat(payslip.salary_gross),
                payslip.currency ?? "USD"
              )}
            </PayslipsText>
          </TableCell>
          <TableCell>
            <PayslipsText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {currencyFormatter(
                parseFloat(payslip.salary_gross) -
                  parseFloat(payslip.salary_net),
                payslip.currency ?? "USD"
              )}
            </PayslipsText>
          </TableCell>
          <TableCell>
            <PayslipsText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {currencyFormatter(
                parseFloat(payslip.salary_net),
                payslip.currency ?? "USD"
              )}
            </PayslipsText>
          </TableCell>
          <TableCell>
            <ActionsDropDown
              actions={[
                {
                  text: "View",
                  onSelect: () => {
                    navigate(`/people/profile/1/payslip-details/${payslip.id}`);
                  },
                },
              ]}
            />
          </TableCell>
        </TableRow>
      );
    });
  };

  const renderPayslipsTable = () => {
    return (
      <PayslipsContainer>
        <PayslipsHeader>
          <PayslipsTitle size={FontSizes.TX_LG} weight={FontWeights.SEMIBOLD}>
            Payslips
          </PayslipsTitle>
        </PayslipsHeader>
        <Table>
          <TableHeader>
            <TableCell>
              <TableHeadWithIcon
                onClick={() =>
                  dispatch(
                    updateEmployeePayslipsTableVariables({
                      sort: "payPeriod",
                    })
                  )
                }
              >
                <Typography size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                  Pay period
                </Typography>
                <Sort
                  sortKey="payPeriod"
                  currentSortKey={employeePayslipsTableVariables.sort}
                  sortDirection={employeePayslipsTableVariables.sort_dir}
                  onClick={changeSortDirection}
                />
              </TableHeadWithIcon>
            </TableCell>
            <TableCell>
              <TableHeadWithIcon
                onClick={(e) =>
                  dispatch(
                    updateEmployeePayslipsTableVariables({
                      sort: "paymentDate",
                    })
                  )
                }
              >
                <Typography size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                  Payment date
                </Typography>
                <Sort
                  sortKey="paymentDate"
                  currentSortKey={employeePayslipsTableVariables.sort}
                  sortDirection={employeePayslipsTableVariables.sort_dir}
                  onClick={changeSortDirection}
                />
              </TableHeadWithIcon>
            </TableCell>
            <TableCell>
              <TableHeadWithIcon
                onClick={(e) =>
                  dispatch(
                    updateEmployeePayslipsTableVariables({
                      sort: "grossPay",
                    })
                  )
                }
              >
                <Typography size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                  Gross pay
                </Typography>
                <Sort
                  sortKey="grossPay"
                  currentSortKey={employeePayslipsTableVariables.sort}
                  sortDirection={employeePayslipsTableVariables.sort_dir}
                  onClick={changeSortDirection}
                />
              </TableHeadWithIcon>
            </TableCell>
            <TableCell>
              <Typography size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                Total deductions
              </Typography>
            </TableCell>
            <TableCell>
              <TableHeadWithIcon
                onClick={(e) =>
                  dispatch(
                    updateEmployeePayslipsTableVariables({
                      sort: "netPay",
                    })
                  )
                }
              >
                <Typography size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                  Net Pay
                </Typography>
                <Sort
                  sortKey="netPay"
                  currentSortKey={employeePayslipsTableVariables.sort}
                  sortDirection={employeePayslipsTableVariables.sort_dir}
                  onClick={changeSortDirection}
                />
              </TableHeadWithIcon>
            </TableCell>
            <TableCell></TableCell>
          </TableHeader>
          {renderPayslipsRows()}
          {(data?._metadata?.last_page ?? 0) > 1 && (
            <TableRowDynamic height="68px">
              <TablePagination
                currentPage={data?._metadata?.page ?? 1}
                totalPages={data?._metadata?.last_page ?? 1}
                setCurrentPage={(page) => {
                  dispatch(updateEmployeePayslipsTableVariables({ page }));
                }}
              />
            </TableRowDynamic>
          )}
        </Table>
      </PayslipsContainer>
    );
  };

  return data?.payslips?.length === 0 || !data ? (
    <PeopleEmptyState
      title="You don’t have payslips for this employee"
      btnText="Check Payments"
      onBtnClick={() => {}}
    />
  ) : (
    renderPayslipsTable()
  );
};

export default Payslips;
