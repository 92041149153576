import {
  ButtonSizes,
  ButtonVariants,
  Colors,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import { OVARC_BANK_ACCOUNT } from "../../../../mockServer/MockData";
import { useAppDispatch, useAppSelector } from "../../../../redux/reduxHooks";
import { onPayInvoicesPreviousStep, PAYROLL_PROCESS_STEPS, setSelectedInvoices } from "../../../../redux/slices/paymentSlice";
import { currencyFormatter } from "../../../../utilities/currencyFormatter";
import Button from "../../../shared/Button";
import {
  CardHeader,
  CardRowTitle,
  CardRowValue,
  CardTitle,
} from "../../../shared/Card";
import {
  ConfirmationStepContainer,
  StepTitle,
  StepTitleCard,
  ConfirmationCard,
  ConfirmationCardRow,
  ButtonsContainer,
} from "./styled";
import ArrowBack from "../../../../Images/icons/arrow-left.svg";
import { useEffect, useState } from "react";
import TransactionNumberModal from "../../TransactionNumberModal";
import { useCreatePayment, useGetCurrentBalance, useGetInternalBankAccount } from "../../../../queries/payment";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { SnackbarTypes } from "../../../../types/componentsProps";
import Typography from "../../../shared/Typography";
import { setInternalBankId } from "../../../../redux/slices/companiesSlice";
import { useQueryClient } from "@tanstack/react-query";
import { setPayrollProcessSteps } from "../../../../redux/slices/payrollSlice";
import { useNavigate } from "react-router-dom";

const Confirmation = () => {
  const {
    pay_invoices_steps,
    current_pay_invoices_step,
    totalPayment,
    selected_invoices,
    pay_invoices_variables
  } = useAppSelector((state) => state.payment);

  const navigate = useNavigate();
  const { activeCompany, currentInternalBankId } = useAppSelector((state) => state.companies);
  const dispatch = useAppDispatch();
  const { showSnackbar } = useSnackbar();
  const { data: balance, error: balanceError } = useGetCurrentBalance(activeCompany?.id);
  const { data: bank, error: bankError } = useGetInternalBankAccount(selected_invoices[0]?.currency)
  const [openModal, setOpenModal] = useState(false);
  const [ difference, setDifference ] = useState<number>(totalPayment);
  
  const queryClient = useQueryClient();
  const { mutateAsync: createPayment } = useCreatePayment({
    invoice_ids: selected_invoices.map((invoice) => invoice.id),
    method: pay_invoices_variables.paymentMethod,
    transaction_id: difference > 0 ? pay_invoices_variables.transactionReferenceNumber: "",
    internal_bank_account_id: currentInternalBankId,
    company_id: activeCompany?.id || "",
    manualTransferMethod: pay_invoices_variables.manualTransferMethod,
    balance_id: balance?.current ? balance.id : undefined,
  });

  useEffect(() => {
    if(balance) {
      setDifference(totalPayment - balance.current);
    }
  }, [balance])

  useEffect(() => {
    if (balanceError) {
      showSnackbar({
        open: true,
        type: SnackbarTypes.ERROR,
        title: "Something went wrong while fetching balance",
        description: "Please try again later",
      });
    }
  }, [balanceError]);

  useEffect(() => {
    if (bankError) {
      showSnackbar({
        open: true,
        type: SnackbarTypes.ERROR,
        title: "Something went wrong while fetching internal bank details",
        description: "Please try again later",
      });
    }
  }, [bankError]);

  useEffect(() => {
    if(bank) {
      dispatch(setInternalBankId(bank.id));
    }
  },[bank])

  const handlePayment = async () => {
    try {
      await createPayment();
      queryClient.invalidateQueries({
        queryKey: ["paymentsList", "payment", "invoices"],
      });
      dispatch(setSelectedInvoices([]));
      dispatch(setPayrollProcessSteps(PAYROLL_PROCESS_STEPS));
      showSnackbar({
        type: SnackbarTypes.SUCCESS,
        open: true,
        title: "Your payment has been successfully created.",
        description: "",
      });
      navigate("/payment");
    } catch (e) {
      showSnackbar({
        type: SnackbarTypes.ERROR,
        open: true,
        title: "Something went wrong while creating payment.",
        description: "Please try again later.",
      });
    }
  };

  return (
    <ConfirmationStepContainer>
      <StepTitleCard>
        <StepTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Step {current_pay_invoices_step + 1}:{" "}
          {pay_invoices_steps[current_pay_invoices_step].title}
        </StepTitle>
      </StepTitleCard>

      <ConfirmationCard>
        <CardHeader>
          <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            Payment Details
          </CardTitle>
        </CardHeader>
        {!balance?.current ? (
          <ConfirmationCardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Amount Due
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
                { currencyFormatter(totalPayment, selected_invoices[0]?.currency) }
            </CardRowValue>
          </ConfirmationCardRow>
        ) : (
          <>
            <ConfirmationCardRow>
              <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Subtotal
              </CardRowTitle>
              <CardRowValue size={FontSizes.TX_MD}>
                  { currencyFormatter(totalPayment, selected_invoices[0]?.currency) }
              </CardRowValue>
            </ConfirmationCardRow>
            <ConfirmationCardRow>
              <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Balance
              </CardRowTitle>
              <CardRowValue size={FontSizes.TX_MD}>
                  { currencyFormatter(balance.current,balance.currency) }
              </CardRowValue>
            </ConfirmationCardRow>
            <ConfirmationCardRow style={{background: Colors.Blue_light_50}}>
              <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Total Due
              </CardRowTitle>
              <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
              { balance.current >= totalPayment
                ? currencyFormatter(0, balance.currency) 
                : currencyFormatter(totalPayment - balance.current, balance.currency) }
              </CardRowValue>
            </ConfirmationCardRow>
          </>
        )}
      </ConfirmationCard>
      {difference > 0 ? (
        <>
          <ConfirmationCard>
            <CardHeader>
              <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
                Manual transfer details
              </CardTitle>
            </CardHeader>
            <ConfirmationCardRow>
              <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Method
              </CardRowTitle>
              <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
                Bank Transfer{" "}
              </CardRowValue>
            </ConfirmationCardRow>

            <ConfirmationCardRow>
              <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Account Location
              </CardRowTitle>
              <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
                Egypt
              </CardRowValue>
            </ConfirmationCardRow>

            <ConfirmationCardRow>
              <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Currency
              </CardRowTitle>
              <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
                {activeCompany?.invoice_currency}
              </CardRowValue>
            </ConfirmationCardRow>
          </ConfirmationCard>

          <ConfirmationCard>
            <CardHeader>
              <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
                Ovarc bank account details
              </CardTitle>
            </CardHeader>
            <ConfirmationCardRow>
              <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Currency
              </CardRowTitle>
              <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
                {bank?.currency}
              </CardRowValue>
            </ConfirmationCardRow>

            {/* <ConfirmationCardRow>
              <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Beneficiary
              </CardRowTitle>
              <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
                {bank?.bank_name}
              </CardRowValue>
            </ConfirmationCardRow> */}

            <ConfirmationCardRow>
              <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Bank Name
              </CardRowTitle>
              <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
                {bank?.bank_name}
              </CardRowValue>
            </ConfirmationCardRow>

            <ConfirmationCardRow>
              <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Account Number
              </CardRowTitle>
              <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
                {bank?.account_number}
              </CardRowValue>
            </ConfirmationCardRow>

            <ConfirmationCardRow>
              <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Swift Code
              </CardRowTitle>
              <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
                {bank?.swift_code}
              </CardRowValue>
            </ConfirmationCardRow>

            <ConfirmationCardRow>
              <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                IBAN
              </CardRowTitle>
              <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
                {bank?.iban}
              </CardRowValue>
            </ConfirmationCardRow>

            {/* <ConfirmationCardRow>
              <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Address
              </CardRowTitle>
              <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
                {OVARC_BANK_ACCOUNT.address}
              </CardRowValue>
            </ConfirmationCardRow> */}
          </ConfirmationCard>
        </>
      ) : null}

      <ButtonsContainer>
        <Button
          text="Back"
          variant={ButtonVariants.secondary_gray}
          size={ButtonSizes.lg}
          iconLeading={<img src={ArrowBack} alt="arrow-back" />}
          onClick={() => {
            dispatch(onPayInvoicesPreviousStep());
          }}
        />
        <Button
          text={difference > 0 ? "Pay" : "Confirm"}
          variant={ButtonVariants.primary}
          size={ButtonSizes.lg}
          style={{ width: "40%" }}
          onClick={() => {
            if(difference > 0) {
              setOpenModal(true);
            } else handlePayment();
          }}
        />
      </ButtonsContainer>
      <TransactionNumberModal
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
    </ConfirmationStepContainer>
  );
};

export default Confirmation;
