import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../constants/styles";
import Typography from "../Typography";
import { Link } from "react-router-dom";

export const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  background-color: ${Colors.bg_primary};
  border-right: 1px solid ${Colors.border_secondary};
  padding: ${Spaces.spacing_4xl} ${Spaces.spacing_xl};
  position: relative;
  height: 100%;
  box-sizing: border-box;
  justify-content: flex-start;
  gap: ${Spaces.spacing_3xl};
  position: fixed;
  min-width: 200px;

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const SideBarFooter = styled.div`
  bottom: ${Spaces.spacing_4xl};
  border-top: 1px solid ${Colors.border_secondary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: ${Spaces.spacing_3xl};
  width: calc(100% - ${Spaces.spacing_xl} * 2);
`;

export const UserDataContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const UserName = styled(Typography)`
  color: ${Colors.text_secondary_700};
`;

export const UserEmail = styled(Typography)`
  color: ${Colors.text_tertiary_600};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const LogOutBtn = styled.img`
  cursor: pointer;
`;

export const SideBarEntryContainer = styled(Link)<{
  active: boolean;
  disabled?: boolean;
}>`
  display: flex;
  gap: ${Spaces.spacing_lg};
  align-items: center;
  padding: ${Spaces.spacing_md} ${Spaces.spacing_lg};
  text-decoration: none;
  justify-content: space-between;

  background-color: ${(props) =>
    props.active ? Colors.utility_gray_blue_100 : "transparent"};
  border-radius: ${Radiuses.radius_md};
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
`;

export const SupportEntriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_lg};
  margin-bottom: ${Spaces.spacing_3xl};
`;
export const SideBarEntryTitle = styled(Typography)`
  color: ${Colors.text_secondary_700};
`;
export const SideBarEntryIcon = styled.img``;

export const BottomSection = styled.div`
  width: 90%;
  position: absolute;
  bottom: ${Spaces.spacing_4xl};
`;

export const SideBarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const NotificationBtn = styled.img`
  cursor: pointer;
`;

export const EntryRightSide = styled.div`
  display: flex;
  gap: ${Spaces.spacing_lg};
  align-items: center;
`;

export const Chevron = styled.img`
  cursor: pointer;
`;

export const ChildContainer = styled(Link)<{
  active: boolean;
  disabled?: boolean;
}>`
  display: flex;
  text-decoration: none;
  padding-block: ${Spaces.spacing_md} ${Spaces.spacing_lg};
  padding-inline: calc(24px + ${Spaces.spacing_lg});

  background-color: ${(props) =>
    props.active ? Colors.utility_gray_blue_100 : "transparent"};
  border-radius: ${Radiuses.radius_md};
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
`;

export const AppLogo = styled.img`
  cursor: pointer;
`;
