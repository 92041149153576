import {
  PaymentContainer,
  PaymentDetailsHeader,
  PaymentDetailsTitle,
  TitleRow,
} from "./styled";
import { ButtonSizes, ButtonVariants, FontSizes, FontWeights } from "../../../../constants/styles";
import Tabs from "../../../../components/shared/Tabs";
import { useState } from "react";
import DetailsTab from "./DetailsTab";
import InvoicesTab from "./InvoicesTab";
import Button from "../../../../components/shared/Button";
import { useAppSelector } from "../../../../redux/reduxHooks";
import PaymentStatusModal from "../PaymentStatusModal";

const PaymentDetailsAdmin = () => {
  const { selected_payment } = useAppSelector((state) => state.payment)
  const [activeTab, setActiveTab] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return <DetailsTab />;
      case 1:
        return <InvoicesTab />;
      default:
        return null;
    }
  };

  return (
    <PaymentContainer>
      <PaymentDetailsHeader>
        <TitleRow>
          <PaymentDetailsTitle size={FontSizes.SM} weight={FontWeights.SEMIBOLD}>
            {selected_payment?.public_id}
          </PaymentDetailsTitle>
          <Button
            text="Update Status"
            variant={ButtonVariants.primary}
            size={ButtonSizes.md}
            onClick={() => {
              if(selected_payment?.status !== "Confirmed") {
                setOpenModal(true)
              }
            }}
          />
        </TitleRow>
        <Tabs
          tabsTitles={["Payment Details", "Invoices"]}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </PaymentDetailsHeader>
      {renderTabContent()}
      <PaymentStatusModal
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
    </PaymentContainer>
  );
}

export default PaymentDetailsAdmin