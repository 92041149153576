import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../queries/apiClient";
import { Company } from "../../types/companies";

export const useGetCompaniesListAdmin = () => {
   return useQuery<Company[], Error>({
   queryFn: async () => {
      const response = await apiClient.get(
         `/admin/company`
      );
      return response.data.companies;
   },
   queryKey: ["companiesListAdmin"],
   });
};
