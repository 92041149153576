import { useEffect, useState } from 'react'
import { DropdownListOption, ModalProps, SnackbarTypes } from '../../../../types/componentsProps';
import Modal from '../../../../components/shared/Modal';
import { CloseIcon, ContentContainer, ModalFooter, ModalHeader, ModalTitle } from './styled';
import { ButtonSizes, ButtonVariants, FontSizes, FontWeights } from '../../../../constants/styles';
import InputField from '../../../../components/shared/InputField';
import Button from '../../../../components/shared/Button';
import Close from "../../../../Images/icons/x-close.svg";
import DropdownList from '../../../../components/shared/DropdownList';
import { PAYMENT_STATUS_DROPDOWN } from '../../../../types/payments';
import { useAppSelector } from '../../../../redux/reduxHooks';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import { useConfirmPaymentAdmin } from '../../../queries/payment';
import { useQueryClient } from '@tanstack/react-query';

const PaymentStatusModal = ({
   open,
   onClose,
 }: {
   open: ModalProps["open"];
   onClose: ModalProps["onClose"];
 }) => {
   const { showSnackbar } = useSnackbar();
   const queryClient = useQueryClient();

   const {selected_payment} = useAppSelector((state) => state.payment)

   const {mutateAsync: confirmPayment, error} = useConfirmPaymentAdmin();

   const [formData, setFormData] = useState({
      status: {label: "", value: ""} as DropdownListOption,
      transactionAmount: "",
      confirmedTransactionAmount: "",
      transactionError: "",
   });

   useEffect(() => {
      if(selected_payment) {
         setFormData({
            ...formData,
            status: PAYMENT_STATUS_DROPDOWN.find((option) => option.label === selected_payment.status) || formData.status
         });
      }
   }, [selected_payment])

   const handleClose = () => {
      setFormData({
         transactionAmount: "",
         confirmedTransactionAmount: "",
         transactionError: "",
         status: PAYMENT_STATUS_DROPDOWN.find((option) => option.label === selected_payment?.status) ||
         {label: "", value: ""}
      });
      onClose();
   }

   const handleSubmit = async () => {
      try {
         if(formData.confirmedTransactionAmount !== formData.transactionAmount) {
            setFormData((prevData) => ({...prevData, transactionError: "Values do not match."}))
         } else if(selected_payment) {
            await confirmPayment({
               id: selected_payment?.id,
               payment_status: formData.status.label,
               value_paid: formData.transactionAmount ? parseFloat(formData.transactionAmount) : 0,
            })
            queryClient.invalidateQueries({
               queryKey: ["paymentsList"],
             });
            handleClose();
            showSnackbar({
               open: true,
               type: SnackbarTypes.SUCCESS,
               title: "Success",
               description: "Payment Status Updated.",
             });
         }
      } catch(err) {
         handleClose();
         showSnackbar({
            open: true,
            type: SnackbarTypes.ERROR,
            title: "Error",
            description: "Failed to confirm payment.",
          });
      }
   }

   const isDisabled = () => {
      if(selected_payment) {
         if(selected_payment.status === formData.status.label) {
            return true;
         } else if(formData.status.label !== 'Failed' && (formData.transactionAmount === "" ||
            formData.confirmedTransactionAmount === "")) {
            return true;
         } else return false;
      } else return true;
   }
   
   return (
      <Modal onClose={handleClose} open={open}>
         <ContentContainer>
            <ModalHeader>
               <ModalTitle size={FontSizes.TX_LG} weight={FontWeights.SEMIBOLD}>
                  Change/Update Status
               </ModalTitle>
               <CloseIcon onClick={handleClose} src={Close} />
            </ModalHeader>
            <DropdownList
               label='Status'
               options={PAYMENT_STATUS_DROPDOWN.slice(1)}
               selectedOption={formData.status}
               setSelectedOption={(option) =>
                  setFormData({ ...formData, status: option })
               }
            />
            { formData.status.label !== 'Failed' && (
            <>
               <InputField
                  label="Transaction Amount"
                  type='number'
                  containerStyle={{ width: "100%" }}
                  value={formData.transactionAmount}
                  onChange={(e) => {
                     setFormData({...formData, transactionAmount: e.target.value})
                  }}
               />
               <InputField
                  label="Confirm Transaction Amount"
                  type='number'
                  containerStyle={{ width: "100%" }}
                  value={formData.confirmedTransactionAmount}
                  error={formData.transactionError}
                  onChange={(e) => {
                     setFormData({...formData, confirmedTransactionAmount: e.target.value})
                  }}
                  onFocus={() => {
                     setFormData((prevData) => ({...prevData, transactionError: ""}));
                  }}
               />
            </>)}
            <ModalFooter>
               <Button
                  text="Cancel"
                  variant={ButtonVariants.secondary_gray}
                  size={ButtonSizes.lg}
                  style={{ width: "100%" }}
                  onClick={handleClose}
               />

               <Button
                  text="Confirm"
                  variant={ButtonVariants.primary}
                  size={ButtonSizes.lg}
                  style={{ width: "100%" }}
                  disabled={isDisabled()}
                  onClick={() => {handleSubmit()}}
               />
            </ModalFooter>
         </ContentContainer>
      </Modal>
   )
}

export default PaymentStatusModal