import styled from "styled-components";
import { Colors, FontWeights, Spaces } from "../../constants/styles";
import Typography from "../../components/shared/Typography";

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${Colors.bg_active};
  flex: 1;
  padding-inline: ${Spaces.spacing_xl};
  overflow-y: auto;
  scroll-behavior: auto;
  scrollbar-width: none;
  height: 100%;
`;

export const DashboardHeader = styled.div`
  width: 100%;
  padding-bottom: ${Spaces.spacing_2xl};
  border-bottom: 1px solid ${Colors.border_secondary};
`;

export const PageTitle = styled(Typography)`
  color: ${Colors.text_primary};
  margin-bottom: ${Spaces.spacing_xs};
`;

export const PageSubtitle = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const UserName = styled.span`
  font-weight: ${FontWeights.BOLD};
`;

export const DashboardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: ${Spaces.spacing_xl};
`;

export const CardsContainer = styled.div`
  display: flex;
  gap: ${Spaces.spacing_md};
  flex-wrap: wrap;
`;

export const Grid = styled.div<{ hasBanners: boolean }>`
  display: grid;
  margin-block: ${Spaces.spacing_md};

  column-gap: ${Spaces.spacing_md};
  row-gap: ${Spaces.spacing_lg};
  grid-template-areas:
    "upcoming payments "
    "onboarding payments"
    "banners payments"
    "contactUs contactUs";

  grid-template-columns: 1fr 1fr;

  ${(props) =>
    !props.hasBanners &&
    `
    grid-template-areas:
    "upcoming payments "
    "upcoming payments"
    "onboarding payments"
    "companies contactUs";
    grid-template-rows: 1fr 1fr 1fr 1fr;
  `}

  @media screen and (max-width: 950px) {
    grid-template-columns: 1fr;

    grid-template-areas: ${(props) =>
      props.hasBanners
        ? `    "upcoming"
      "onboarding"
      "banners"
      "payments"
      "companies"
      "contactUs";`
        : ` "upcoming"
      "onboarding"
     "payments"
       "companies"
      "contactUs";`};
  }
`;
