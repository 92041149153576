import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DropdownListOption } from "../../types/componentsProps";
import { sortDirection } from "../../types/apisTypes";
import { CreateNewEmployeeContractSteps } from "../../constants/people";
import { ALL_STATUS_OPTIONS } from "../../constants/dropDownLists";
import { Asset, Employee } from "../../types/people";

interface PeopleState {
  employees_table_variables: {
    search: string;
    selectedStatus: DropdownListOption;
    currentPage: number;
    totalRecords: number;
    sortKey: keyof Employee;
    sortDirection: sortDirection;
    filteredEmployees: Employee[] | null;
    itemsPerPage: number;
    totalPages: number;
  };
  activeEmployeeName: string;
  activeEmployeeAssets: Asset[] | null;
  availableAssets: Asset[] | null;
  currentStep: number;
  newContractSteps: typeof CreateNewEmployeeContractSteps;
  newContractData: {
    executive_level: string;
    job_title: string;
    job_scope: string;
    employment_type: string;
    hours_per_week: string;
    net_salary: string;
    currency: string;
    start_date: string;
    employment_term: number;
    health_insurance?: {
      provider: string;
      plan: string;
      enrollment_date: string;
    };
    social_insurance?: {
      provider: string;
      plan: string;
    };
    signing_bonus?: string;
    assets?: {
      name: string;
      type: string;
      brand: string;
    }[];
  };
  newContractAssetsList: Asset[];
  departmentOptions: DropdownListOption[];
  jobTitleOptions: {
    value: string;
    label: string;
    description: string;
    category: string;
  }[];
  employeePayslipsTableVariables: {
    page: number;
    sort: string;
    sort_dir: "ASC" | "DESC";
    limit: number;
  };
}

const initialState: PeopleState = {
  employees_table_variables: {
    search: "",
    selectedStatus: ALL_STATUS_OPTIONS[0],
    currentPage: 1,
    totalRecords: 0,
    sortKey: "created_at" as keyof Employee,
    sortDirection: "ASC",
    filteredEmployees: null,
    itemsPerPage: 7,
    totalPages: 0,
  },

  activeEmployeeName: "",
  activeEmployeeAssets: null,
  availableAssets: null,
  newContractSteps: [...CreateNewEmployeeContractSteps],
  newContractData: {
    executive_level: "",
    job_title: "",
    job_scope: "",
    employment_type: "",
    hours_per_week: "",
    net_salary: "",
    currency: "",
    start_date: "",
    employment_term: 6,
  },
  currentStep: 0,
  newContractAssetsList: [],
  departmentOptions: [],
  jobTitleOptions: [],
  employeePayslipsTableVariables: {
    page: 1,
    sort: "payPeriod",
    sort_dir: "DESC",
    limit: 7,
  },
};

const peopleSlice = createSlice({
  name: "people",
  initialState,
  reducers: {
    setEmployeesTableVariables(
      state,
      action: PayloadAction<Partial<PeopleState["employees_table_variables"]>>
    ) {
      state.employees_table_variables = {
        ...state.employees_table_variables,
        ...action.payload,
      };
    },
    setActiveEmployeeName(state, action: PayloadAction<string>) {
      state.activeEmployeeName = action.payload;
    },

    setActiveEmployeeAssets(state, action: PayloadAction<Asset[] | null>) {
      state.activeEmployeeAssets = action.payload;
    },
    setAvailableAssets(state, action: PayloadAction<Asset[] | null>) {
      state.availableAssets = action.payload;
    },
    updateNewContractData(
      state,
      action: PayloadAction<Partial<PeopleState["newContractData"]>>
    ) {
      state.newContractData = { ...state.newContractData, ...action.payload };
    },
    setNewContractData(
      state,
      action: PayloadAction<PeopleState["newContractData"]>
    ) {
      state.newContractData = action.payload;
    },
    setCreateNewEmployeeContractSteps(
      state,
      action: PayloadAction<typeof CreateNewEmployeeContractSteps>
    ) {
      state.newContractSteps = action.payload;
    },
    setCurrentStep(state, action: PayloadAction<number>) {
      state.currentStep = action.payload;
    },
    setNewContractAssetList(state, action: PayloadAction<Asset[]>) {
      state.newContractAssetsList = action.payload;
    },
    setDepartmentOptions(state, action: PayloadAction<DropdownListOption[]>) {
      state.departmentOptions = action.payload;
    },
    setJobTitleOptions(
      state,
      action: PayloadAction<
        {
          label: string;
          value: string;
          description: string;
          category: string;
        }[]
      >
    ) {
      state.jobTitleOptions = action.payload;
    },
    updateEmployeePayslipsTableVariables(
      state,
      action: PayloadAction<
        Partial<PeopleState["employeePayslipsTableVariables"]>
      >
    ) {
      state.employeePayslipsTableVariables = {
        ...state.employeePayslipsTableVariables,
        ...action.payload,
      };
    },
  },
});

export const {
  setEmployeesTableVariables,
  setActiveEmployeeName,
  setActiveEmployeeAssets,
  setAvailableAssets,
  setNewContractData,
  updateNewContractData,
  setCreateNewEmployeeContractSteps,
  setCurrentStep,
  setNewContractAssetList,
  setDepartmentOptions,
  setJobTitleOptions,
  updateEmployeePayslipsTableVariables,
} = peopleSlice.actions;

export default peopleSlice.reducer;
