import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import Button from "../../../shared/Button";
import {
  BannerDescription,
  ButtonsContainer,
  ContractFileContainer,
  ContractFileName,
  ContractSummaryCard,
  ContractSummaryCardRowValue,
  DownloadButton,
  EgyptBanner,
  InviteEmployeeBanner,
  SummaryContainer,
  SummarySubtitle,
  SummaryTitle,
} from "./styled";
import {
  CardHeader,
  CardRow,
  CardRowSubValue,
  CardRowTitle,
  CardTitle,
} from "../../../shared/Card";
import ArrowBack from "../../../../Images/icons/arrow-left.svg";
import Alert from "../../../../Images/icons/warningTriangle.svg";
import PDF from "../../../../Images/icons/pdf.svg";
import DownLoad from "../../../../Images/icons/download-.svg";
import Info from "../../../../Images/icons/alertCircleGray.svg";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { SnackbarTypes } from "../../../../types/componentsProps";
import { useAppSelector } from "../../../../redux/reduxHooks";
import { currencyFormatter } from "../../../../utilities/currencyFormatter";
import {
  useCreateEmployeeContract,
  useGetContractPreview,
  useGetQuoteSummary,
} from "../../../../queries/people";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import Loader from "../../../shared/Loader";
import moment from "moment";
import { downloadFile } from "../../../../utilities/file";

const ContractSummary = ({ onBack }: { onBack: () => void }) => {
  const newContractData = useAppSelector(
    (state) => state.people.newContractData
  );

  const { showSnackbar } = useSnackbar();
  const { id } = useParams();
  const navigate = useNavigate();
  const { mutateAsync: createContract, isPending: isCreatingContract } =
    useCreateEmployeeContract();

  const {
    data: quoteDetails,
    error,
    isPending,
  } = useGetQuoteSummary({
    employee_id: parseInt(id || ""),
    department: newContractData.executive_level,
    job_title: newContractData.job_title,
    employment_term: newContractData.employment_term,
    hours_per_week: parseFloat(newContractData.hours_per_week),
    start_date: newContractData.start_date,
    end_date: moment(newContractData.start_date)
      .add(newContractData.employment_term, "months")
      .format("YYYY-MM-DD"),
    employment_type: newContractData.employment_type,
    job_description: newContractData.job_scope,
    salary: parseFloat(newContractData.net_salary),
    salary_type: 'Net',
    currency: newContractData.currency,
  });

  const { mutateAsync: getContractReview, isPending: contractReviewPending } =
    useGetContractPreview();

  const getContractReviewData = async () => {
    try {
      const file = await getContractReview({
        employee_id: parseInt(id || ""),
        department: newContractData.executive_level,
        job_title: newContractData.job_title,
        employment_term: newContractData.employment_term,
        hours_per_week: parseFloat(newContractData.hours_per_week),
        start_date: newContractData.start_date,
        end_date: moment(newContractData.start_date)
          .add(newContractData.employment_term, "months")
          .format("YYYY-MM-DD"),
        employment_type: newContractData.employment_type,
        job_description: newContractData.job_scope,
        salary: parseFloat(newContractData.net_salary),
        salary_type: "Net",
        currency: newContractData.currency,
      });
      downloadFile(file, `${newContractData.job_title} Contract Preview`,"application/pdf");
    } catch (error) {
      showSnackbar({
        open: true,
        title: "Something went wrong",
        description: "Failed to retrieve data for salary calculations",
        type: SnackbarTypes.ERROR,
      });
    }
  };

  useEffect(() => {
    if (error) {
      showSnackbar({
        open: true,
        title: "Something went wrong",
        description: "Failed to retrieve data for salary calculations",
        type: SnackbarTypes.ERROR,
      });
    }
  }, [error]);

  const queryClient = useQueryClient();

  const onCreateContract = async () => {
    try {
      await createContract({
        employee_id: parseInt(id || ""),
        department: newContractData.executive_level,
        job_title: newContractData.job_title,
        employment_term: newContractData.employment_term,
        hours_per_week: parseFloat(newContractData.hours_per_week),
        start_date: newContractData.start_date,
        end_date: moment(newContractData.start_date)
          .add(newContractData.employment_term, "months")
          .format("YYYY-MM-DD"),
        employment_type: newContractData.employment_type,
        job_description: newContractData.job_scope,
        salary: parseFloat(newContractData.net_salary),
        salary_type: "Net",
        currency: newContractData.currency,
      });
      queryClient.invalidateQueries({ queryKey: ["employees"] });
      navigate(`/people/profile/${id}/contract`);
    } catch (error) {
      showSnackbar({
        open: true,
        title: "Something went wrong",
        description: "Failed to create contract",
        type: SnackbarTypes.ERROR,
      });
    }
  };

  const renderEgyptBanner = () => {
    return (
      <EgyptBanner>
        <img src={Alert} />
        <BannerDescription size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
          In Egypt, we have to send a physical contract to employees. After
          signing the Scope of Work, the contract cannot then be cancelled. All
          monetary obligations towards the employee, including all payments will
          be chargeable to you.
        </BannerDescription>
      </EgyptBanner>
    );
  };

  const renderInviteEmployeeBanner = () => {
    return (
      <InviteEmployeeBanner>
        <img src={Info} />
        <div>
          <BannerDescription
            size={FontSizes.TX_SM}
            weight={FontWeights.REGULAR}
          >
            Once the SOW is signed, the employee will be invited to Ovarc to
            complete their onboarding and sign their employee agreement. If you
            wish to delay this, click the button below.
          </BannerDescription>
          <Button
            text="Invite Employee Manually"
            onClick={() => {}}
            variant={ButtonVariants.link_color}
            size={ButtonSizes.sm}
            style={{
              width: "fit-content",
              padding: "0",
            }}
          />
        </div>
      </InviteEmployeeBanner>
    );
  };

  const renderContractFile = () => {
    return (
      <ContractSummaryCard>
        <CardHeader>
          <CardTitle size={FontSizes.TX_LG} weight={FontWeights.SEMIBOLD}>
            Contract
          </CardTitle>

          {/* <Button
            text="Review & Sign"
            onClick={() => {}}
            variant={ButtonVariants.secondary_color}
            size={ButtonSizes.sm}
            style={{
              width: "fit-content",
            }}
          /> */}
        </CardHeader>
        <ContractFileContainer>
          <img src={PDF} />
          <ContractFileName size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
            Contract.pdf
          </ContractFileName>
          <DownloadButton
            onClick={() => {
              getContractReviewData();
            }}
          >
            <img src={DownLoad} />
          </DownloadButton>
        </ContractFileContainer>
        {/* {renderInviteEmployeeBanner()} */}
      </ContractSummaryCard>
    );
  };

  const renderQuoteSummary = () => {
    return (
      quoteDetails && (
        <ContractSummaryCard>
          <CardHeader>
            <CardTitle size={FontSizes.TX_LG} weight={FontWeights.SEMIBOLD}>
              Quote Summary
            </CardTitle>

            {/* <Button
              text="Download"
              onClick={() => {}}
              variant={ButtonVariants.tertiary_gray}
              size={ButtonSizes.md}
              style={{
                width: "fit-content",
              }}
            /> */}
          </CardHeader>
          {/* <CardSubtitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Monthly Payroll
          </CardSubtitle> */}
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Total
            </CardRowTitle>
            <ContractSummaryCardRowValue
              size={FontSizes.TX_SM}
              weight={FontWeights.SEMIBOLD}
            >
              {currencyFormatter(
                quoteDetails ? parseFloat(quoteDetails.total) : 0,
                quoteDetails?.currency
              )}
            </ContractSummaryCardRowValue>
          </CardRow>
          {renderRows()}
        </ContractSummaryCard>
      )
    );
  };

  const renderRows = () => {
    return quoteDetails?.line_items.map((item, index) => {
      return (
        <>
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {item.description}
            </CardRowTitle>
            <ContractSummaryCardRowValue
              size={FontSizes.TX_SM}
              weight={FontWeights.SEMIBOLD}
            >
              {currencyFormatter(
                item.quantity * item.unit_price,
                quoteDetails.currency
              )}{" "}
              {item.description == "Ovarc Fees" && (
                <CardRowSubValue>
                  {currencyFormatter(item.unit_price, quoteDetails.currency)}{" "}
                  each month
                </CardRowSubValue>
              )}
            </ContractSummaryCardRowValue>
          </CardRow>
        </>
      );
    });
  };

  const renderBenefits = () => {
    return newContractData.social_insurance ||
      newContractData.health_insurance ? (
      <ContractSummaryCard>
        <CardHeader>
          <CardTitle size={FontSizes.TX_LG} weight={FontWeights.SEMIBOLD}>
            Benefits
          </CardTitle>
        </CardHeader>
        {newContractData.health_insurance && (
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Healthcare
            </CardRowTitle>
            <ContractSummaryCardRowValue
              size={FontSizes.TX_SM}
              weight={FontWeights.SEMIBOLD}
            >
              {newContractData.health_insurance.provider}{" "}
              <CardRowSubValue>
                ({newContractData.health_insurance.plan} Plan)
              </CardRowSubValue>{" "}
              {"\n"}
              <CardRowSubValue>
                Starting {newContractData.health_insurance.enrollment_date}
              </CardRowSubValue>
            </ContractSummaryCardRowValue>
          </CardRow>
        )}

        {newContractData.social_insurance && (
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Social Insurance
            </CardRowTitle>
            <ContractSummaryCardRowValue
              size={FontSizes.TX_SM}
              weight={FontWeights.SEMIBOLD}
            >
              {newContractData.social_insurance.provider}{" "}
              <CardRowSubValue>
                ({newContractData.social_insurance.plan} Plan)
              </CardRowSubValue>
            </ContractSummaryCardRowValue>
          </CardRow>
        )}

        {newContractData.signing_bonus && (
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Signing Bonus
            </CardRowTitle>
            <ContractSummaryCardRowValue
              size={FontSizes.TX_SM}
              weight={FontWeights.SEMIBOLD}
            >
              {currencyFormatter(Number(newContractData.signing_bonus), "USD")}
            </ContractSummaryCardRowValue>
          </CardRow>
        )}
      </ContractSummaryCard>
    ) : null;
  };
  const renderAssets = () => {
    return newContractData.assets ? (
      <ContractSummaryCard>
        <CardHeader>
          <CardTitle size={FontSizes.TX_LG} weight={FontWeights.SEMIBOLD}>
            Assets
          </CardTitle>
        </CardHeader>

        {newContractData.assets?.map((asset: any, index: number) => {
          return (
            <CardRow>
              <CardRowTitle
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                Asset {index + 1}
              </CardRowTitle>
              <ContractSummaryCardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {asset.name}
                {"\n"}
                <CardRowSubValue>
                  {asset.type} - {asset.brand}
                </CardRowSubValue>
              </ContractSummaryCardRowValue>
            </CardRow>
          );
        })}
      </ContractSummaryCard>
    ) : null;
  };

  return (
    <SummaryContainer>
      <SummaryTitle size={FontSizes.XS} weight={FontWeights.SEMIBOLD}>
        Contract Summary
      </SummaryTitle>
      <SummarySubtitle size={FontSizes.TX_MD} weight={FontWeights.REGULAR}>
        Summarize key terms and conditions for mutual understanding.
      </SummarySubtitle>
      {isPending || contractReviewPending ? (
        <Loader />
      ) : (
        <>
          {renderEgyptBanner()}
          {renderContractFile()}
          {renderQuoteSummary()}
          {/* {renderBenefits()}
        {renderAssets()} */}
          <ButtonsContainer>
            <Button
              text="Back"
              onClick={onBack}
              variant={ButtonVariants.secondary_gray}
              size={ButtonSizes.lg}
              style={{
                width: "fit-content",
              }}
              iconLeading={<img src={ArrowBack} />}
            />
            <Button
              text="Create Contract"
              onClick={() => {
                onCreateContract();
              }}
              variant={ButtonVariants.primary}
              size={ButtonSizes.lg}
              style={{
                width: "50%",
              }}
              loading={isCreatingContract}
            />
          </ButtonsContainer>
        </>
      )}
    </SummaryContainer>
  );
};

export default ContractSummary;
