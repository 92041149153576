import React from "react";
import { Link } from "react-router-dom";
import { Logo, SidePanelContainer } from "./styled";
import { CopyRightText } from "../../shared/CopyRight";
import { Colors } from "../../../constants/styles";
import EmailButton from "../../shared/EmailButton";
import OvarcLogoWhite from "../../../Images/logos/LogoWhite.svg";
import {
  RegistrationSteps,
  registrationSteps,
} from "../../../constants/registration";
import AccountTypeContent from "./AccountTypeContent";
import RegistrationStepper from "../RegistrationStepper";
import { useAppDispatch } from "../../../redux/reduxHooks";
import { removeUser } from "../../../redux/slices/authSlice";

const SidePanel: React.FC = () => {
  const dispatch = useAppDispatch();

  return (
    <SidePanelContainer>
      <Link to="/" style={{ width: "fit-content", height: "fit-content" }} onClick={() => dispatch(removeUser())}>
        <Logo src={OvarcLogoWhite} alt="ovarc-logo" />
      </Link>
      <AccountTypeContent />
      <CopyRightText style={{ color: Colors.text_white }} />
      <EmailButton />
    </SidePanelContainer>
  );
};

export default SidePanel;
