import { UPCOMING_ACTIONS_DASHBOARD } from "../mockServer/MockData";

export type Company = {
    id: number;
    name: string;
    industry: string;
    phone: string;
    legal_company_name: string;
    tax_id: string | null;
    registration_number: string | null;
    country: number;
    org_id: number;
    plan: number;
    corporation_type: string;
    established: boolean;
    invoice_currency: string;
    company_status: CompanyStatus;
  };
  export enum CompanyStatus {
    NEW = "New",
    PENDING = "Pending",
    VERIFIED = "Verified",
    REJECTED = "Rejected",
    ON_HOLD = "On Hold",
    TERMINATED = "Terminated",
  }
  export type UpcomingActionsDashboardType = typeof UPCOMING_ACTIONS_DASHBOARD
