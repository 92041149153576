import React from "react";
import {
  EmptyStateContainer,
  EmptyStateImg,
  EmptyStateContent,
  EmptyStateTitle,
  EmptyStateDescription,
  WarningContainer,
  WarningTitle,
  WarningDescription,
} from "./styled";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import Button from "../../../shared/Button";
import EmptyStateBannerImg from "../../../../Images/PeopleEmptyStateImg.svg";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../redux/reduxHooks";
import { HOME_SCREEN } from "../../../../constants/routesNames";

interface EmptyStateProps {
  checkStatus?: () => void;
}

const EmptyState: React.FC<EmptyStateProps> = ({ checkStatus }) => {
  const navigate = useNavigate();
  const activeCompany = useAppSelector(
    (state) => state.companies.activeCompany
  );

  return (
    <>
      {!activeCompany ? (
        <WarningContainer>
          <WarningTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Warning
          </WarningTitle>
          <WarningDescription
            size={FontSizes.TX_XS}
            weight={FontWeights.MEDIUM}
          >
            To start using Ovarc services and features, you must create a company profile. Please complete the company registeration to proceed.
          </WarningDescription>
          <Button
            text="Add Company"
            variant={ButtonVariants.primary}
            size={ButtonSizes.sm}
            style={{ width: "fit-content" }}
            onClick={() => navigate(HOME_SCREEN)}
          />
        </WarningContainer>
      ) : null}
      <EmptyStateContainer>
        <EmptyStateContent>
          <EmptyStateTitle size={FontSizes.XS} weight={FontWeights.SEMIBOLD}>
            Effortlessly monitor and manage{"\n"} your employees{" "}
          </EmptyStateTitle>
          <EmptyStateDescription
            size={FontSizes.TX_SM}
            weight={FontWeights.MEDIUM}
          >
            Here, you can effortlessly monitor and manage your employees’
            information. Take control of your workforce by easily updating
            profiles, assigning roles, and tracking performance—all from one
            convenient location. Get started by adding your employees or explore
            the various features available to streamline your HR processes. With
            our intuitive tools, managing your team has never been simpler.
          </EmptyStateDescription>
          {activeCompany && (
            <Button
              text="Add Employee"
              variant={ButtonVariants.secondary_gray}
              size={ButtonSizes.sm}
              style={{ width: "fit-content" }}
              onClick={checkStatus}
            />
          )}
        </EmptyStateContent>
        <EmptyStateImg src={EmptyStateBannerImg} alt="empty state" />
      </EmptyStateContainer>
    </>
  );
};

export default EmptyState;
