import { useParams } from "react-router-dom";
import { Colors, FontSizes, FontWeights } from "../../../../../constants/styles";
import { Card, CardRow, CardRowTitle, CardRowValue, CardTitle } from "../../../../../components/shared/Card";
import { CardsColumn, DetailsTabContainer, PaymentTrackerContainer, StepIcon, StepIconWrapper, StepperConnector, StepSubtitle, StepTitle } from "./styled";
import CompletedIcon from "../../../../../Images/icons/completedStepIcon.svg";
import ActiveIcon from "../../../../../Images/icons/activeStepIcon.svg";
import InactiveIcon from "../../../../../Images/icons/InactiveStepperStepGrey.svg";
import moment from "moment";
import { currencyFormatter } from "../../../../../utilities/currencyFormatter";
import { PAYMENT_STATUS_BADGE_COLOR } from "../../../../../types/payments";
import Badge from "../../../../../components/shared/Badge";
import { useAppSelector } from "../../../../../redux/reduxHooks";
import { useGetPaymentAdmin } from "../../../../queries/payment";

const DetailsTab = () => {
  const { payment_id } = useParams();
  const { totalPayment } = useAppSelector((state) => state.payment);

  const { data: paymentDetails, error } = useGetPaymentAdmin(payment_id); 
 
  const renderPaymentTracker = () => {
    return (
      <Card>
        <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Payment Tracker
        </CardTitle>
        <PaymentTrackerContainer>
          <StepIconWrapper>
            <StepIcon
              src={CompletedIcon}
              width={32}
              height={32}
              alt="step-icon"
              isCurrent={false}
            />
            <StepperConnector isComplete={true} isLast={false} />
          </StepIconWrapper>

          <div>
            <StepTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Payment Initiated
            </StepTitle>
            <StepSubtitle size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {moment(paymentDetails?.created_at).format("DD MMM, YYYY")}
            </StepSubtitle>
          </div>
        </PaymentTrackerContainer>
        {paymentDetails?.created_at ? (
          <PaymentTrackerContainer>
            <StepIconWrapper>
              <StepIcon
                src={CompletedIcon}
                width={32}
                height={32}
                alt="step-icon"
                isCurrent={false}
              />
              <StepperConnector isComplete={true} isLast={true} />
            </StepIconWrapper>

            <div>
              <StepTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Payment received
              </StepTitle>
              <StepSubtitle size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
                {moment(paymentDetails?.created_at).format("DD MMM, YYYY")}
              </StepSubtitle>
            </div>
          </PaymentTrackerContainer>
        ) : (
          <>
            <PaymentTrackerContainer>
              <StepIconWrapper>
                <StepIcon
                  src={ActiveIcon}
                  width={32}
                  height={32}
                  alt="step-icon"
                  isCurrent={false}
                />
                <StepperConnector isComplete={false} isLast={false} />
              </StepIconWrapper>

              <div>
                <StepTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                  Waiting for your payment
                </StepTitle>
                <StepSubtitle
                  size={FontSizes.TX_SM}
                  weight={FontWeights.REGULAR}
                >
                  This may take 2-3 days
                </StepSubtitle>
              </div>
            </PaymentTrackerContainer>
            <PaymentTrackerContainer>
              <StepIconWrapper>
                <StepIcon
                  src={InactiveIcon}
                  width={32}
                  height={32}
                  alt="step-icon"
                  isCurrent={false}
                />
                <StepperConnector isComplete={false} isLast={true} />
              </StepIconWrapper>

              <div>
                <StepTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                  Payment received
                </StepTitle>
                <StepSubtitle
                  size={FontSizes.TX_SM}
                  weight={FontWeights.REGULAR}
                >
                  We will process your payment as soon as we receive it.
                </StepSubtitle>
              </div>
            </PaymentTrackerContainer>
          </>
        )}
      </Card>
    );
  };
 
  return (
    <DetailsTabContainer>
      <CardsColumn>
        <Card>
          <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            Payment Details
          </CardTitle>
          {!paymentDetails?.balance?.current ? (
            <CardRow>
              <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Amount Due
              </CardRowTitle>
              <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
                { currencyFormatter(totalPayment, paymentDetails?.currency) }
              </CardRowValue>
            </CardRow>
          ) : (
            <>
              <CardRow>
                <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                  Subtotal
                </CardRowTitle>
                <CardRowValue size={FontSizes.TX_MD}>
                  { currencyFormatter(totalPayment, paymentDetails?.currency) }
                </CardRowValue>
              </CardRow>
              <CardRow>
                <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                  Balance
                </CardRowTitle>
                <CardRowValue size={FontSizes.TX_MD}>
                  { currencyFormatter(paymentDetails.balance.current,paymentDetails.balance.currency) }
                </CardRowValue>
              </CardRow>
              <CardRow style={{background: Colors.Blue_light_50}}>
                <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                  Total
                </CardRowTitle>
                <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
                  { currencyFormatter(totalPayment - paymentDetails.balance.current, paymentDetails.balance.currency) }
                </CardRowValue>
              </CardRow>
            </>
          )}
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Status
            </CardRowTitle>
            <Badge
              text={paymentDetails?.status || ""}
              dot
              color={PAYMENT_STATUS_BADGE_COLOR[paymentDetails?.status || ""]}
            />
          </CardRow>
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Invoice(s)
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {paymentDetails?.invoices?.length}
            </CardRowValue>
          </CardRow>
        </Card>

        <Card>
          <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            Ovarc bank account details
          </CardTitle>
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Currency
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
              {paymentDetails?.internal_bank_account?.currency}
            </CardRowValue>
          </CardRow>
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Bank Name
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
              {paymentDetails?.internal_bank_account?.bank_name}
            </CardRowValue>
          </CardRow>
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Account Number
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
              {paymentDetails?.internal_bank_account?.account_number}
            </CardRowValue>
          </CardRow>
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Swift Code
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
              {paymentDetails?.internal_bank_account?.swift_code}
            </CardRowValue>
          </CardRow>
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              IBAN
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
              {paymentDetails?.internal_bank_account?.iban}
            </CardRowValue>
          </CardRow>
        </Card>
      </CardsColumn>

      <CardsColumn>{renderPaymentTracker()}</CardsColumn>
    </DetailsTabContainer>
  );
};
 
export default DetailsTab;
 