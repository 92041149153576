export const USER_PAYMENT_PROCESS = {
  user_id: "1",
  total_cost: 150000,
  total_employees: 50,
  currency: "USD",
  companies: [
    {
      id: "1",
      name: "Interlock - Egypt",
      cost: 50000,
      employees: 25,
    },
    {
      id: "2",
      name: "Interlock - UAE",
      cost: 50000,
      employees: 10,
    },
    {
      id: "3",
      name: "Interlock - USA",
      cost: 50000,
      employees: 15,
    },
  ],
};

export const OVARC_BANK_ACCOUNT = {
  currency: "USD",
  beneficiary: "Ovarc",
  account_number: "1234979728",
  swift_code: "234098098222",
  iban: "2984023984098203",
  bank_name: "CIB",
  address: "4140 Parker Rd. Allentown, Cairo ",
};

export const INVOICES_LIST = [
  {
    id: 1,
    public_id: "IND-222",
    description: "5 Laptops",
    status: "Pending",
    type: "Assets",
    total: "5250",
    currency: "USD",
    url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
    created_at: "2023-11-02",
    due_date: "2023-11-16",
    line_items: [
      {
        description: "Salaries",
        quantity: 1,
        unit_price: 4000,
      },
      {
        description: "Ovarc Fees",
        quantity: 5,
        unit_price: 250.00,
      },
    ],
    company: {
      name: "Interlock - Egypt",
      billing_address: "4140 Parker Rd. Allentown, Cairo ",
      registration_number: "PT5222410923",
    },
  },
  {
    "id": 2,
    "public_id": "IND-223",
    "description": "10 Desktops",
    "status": "Approved",
    "type": "Assets",
    "total": "7500",
    "currency": "USD",
    "url": "https://example.com/assets/desktop_purchase.pdf",
    "created_at": "2023-11-01",
    "due_date": "2023-11-20",
    "line_items": [
        {
            "description": "Hardware Costs",
            "quantity": 10,
            "unit_price": 700.00
        },
        {
            "description": "Software Licenses",
            "quantity": 10,
            "unit_price": 50.00
        }
    ],
    "company": {
        "name": "Tech Solutions Inc.",
        "billing_address": "123 Tech Lane, Silicon Valley, CA",
        "registration_number": "TS123456789"
    }
  },
  {
    "id": 3,
    "public_id": "IND-224",
    "description": "20 Tablets",
    "status": "In Review",
    "type": "Assets",
    "total": "3000",
    "currency": "USD",
    "url": "https://example.com/assets/tablet_order.pdf",
    "created_at": "2023-11-02",
    "due_date": "2023-11-25",
    "line_items": [
        {
            "description": "Tablet Units",
            "quantity": 20,
            "unit_price": 150.00
        },
        {
            "description": "Protective Cases",
            "quantity": 20,
            "unit_price": 25.00
        }
    ],
    "company": {
        "name": "Gadget World",
        "billing_address": "456 Gadget Blvd, New York, NY",
        "registration_number": "GW987654321"
    }
  },
  {
    "id": 4,
    "public_id": "IND-225",
    "description": "15 Monitors",
    "status": "Pending",
    "type": "Assets",
    "total": "4500",
    "currency": "USD",
    "url": "https://example.com/assets/monitor_purchase.pdf",
    "created_at": "2023-10-31",
    "due_date": "2023-11-15",
    "line_items": [
        {
            "description": "Monitor Units",
            "quantity": 15,
            "unit_price": 250.00
        },
        {
            "description": "Cables and Accessories",
            "quantity": 15,
            "unit_price": 10.00
        }
    ],
    "company": {
        "name": "Visual Tech Co.",
        "billing_address": "789 Visual St, Austin, TX",
        "registration_number": "VT543216789"
    }
  }




  // {
  //   id: "IND-223",
  //   description: "Printer",
  //   status: "Pending",
  //   category: "Assets",
  //   url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",

  //   total: {
  //     amount: 450,
  //     currency: "USD",
  //   },
  //   issue_date: "2023-11-02",
  //   due_date: "2023-11-16",
  //   bill: {
  //     client_name: "Interlock - Egypt",
  //     address: "4140 Parker Rd. Allentown, Cairo ",
  //     registration_number: "PT5222410923",
  //   },
  //   items: [
  //     {
  //       item_name: "Printer HP 13’",
  //       quantity: 1,
  //       unit_price: 400,
  //       amount: 400,
  //       currency: "USD",
  //     },
  //     {
  //       item_name: "Ovarc Fees",
  //       category: "",
  //       quantity: 1,
  //       unit_price: 50,
  //       amount: 50,
  //       currency: "USD",
  //     },
  //   ],
  // },

  // {
  //   id: "IND-224",
  //   description: "Router",
  //   status: "Pending",
  //   category: "Assets",
  //   url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",

  //   total: {
  //     amount: 200,
  //     currency: "USD",
  //   },
  //   issue_date: "2023-11-02",
  //   due_date: "2023-11-16",
  //   bill: {
  //     client_name: "Interlock - Egypt",
  //     address: "4140 Parker Rd. Allentown, Cairo ",
  //     registration_number: "PT5222410923",
  //   },
  //   items: [
  //     {
  //       item_name: "Router 13’",
  //       quantity: 1,
  //       unit_price: 150,
  //       amount: 150,
  //       currency: "USD",
  //     },
  //     {
  //       item_name: "Ovarc Fees",
  //       category: "",
  //       quantity: 1,
  //       unit_price: 50,
  //       amount: 50,
  //       currency: "USD",
  //     },
  //   ],
  // },

  // {
  //   id: "IND-226",
  //   description: "EOP Payroll",
  //   status: "Pending",
  //   category: "Payroll",
  //   url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",

  //   total: {
  //     amount: 35252,
  //     currency: "USD",
  //   },
  //   issue_date: "2023-11-02",
  //   due_date: "2023-11-16",
  //   bill: {
  //     client_name: "Interlock - Egypt",
  //     address: "4140 Parker Rd. Allentown, Cairo ",
  //     registration_number: "PT5222410923",
  //   },
  //   items: [
  //     {
  //       item_name: "EOP Payroll",
  //       quantity: 1,
  //       unit_price: 30000,
  //       amount: 30000,
  //       currency: "USD",
  //     },
  //     {
  //       item_name: "Ovarc Fees",
  //       category: "",
  //       quantity: 1,
  //       unit_price: 5252,
  //       amount: 5252,
  //       currency: "USD",
  //     },
  //   ],
  // },
  // {
  //   id: "IND-227",
  //   description: "Direct Hire Payroll",
  //   status: "Over due",
  //   category: "Payroll",
  //   url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",

  //   total: {
  //     amount: 25252,
  //     currency: "USD",
  //   },
  //   issue_date: "2023-11-02",
  //   due_date: "2023-11-16",
  //   bill: {
  //     client_name: "Interlock - Egypt",
  //     address: "4140 Parker Rd. Allentown, Cairo ",
  //     registration_number: "PT5222410923",
  //   },
  //   items: [
  //     {
  //       item_name: "Direct Hire Payroll",
  //       quantity: 1,
  //       unit_price: 20000,
  //       amount: 20000,
  //       currency: "USD",
  //     },
  //     {
  //       item_name: "Ovarc Fees",
  //       category: "",
  //       quantity: 1,
  //       unit_price: 5252,
  //       amount: 5252,
  //       currency: "USD",
  //     },
  //   ],
  // },

  // {
  //   id: "IND-228",
  //   description: "Life Insurance",
  //   status: "Pending",
  //   category: "",
  //   url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",

  //   total: {
  //     amount: 3600,
  //     currency: "USD",
  //   },
  //   issue_date: "2023-11-02",
  //   due_date: "2023-11-16",
  //   bill: {
  //     client_name: "Interlock - Egypt",
  //     address: "4140 Parker Rd. Allentown, Cairo ",
  //     registration_number: "PT5222410923",
  //   },
  //   items: [
  //     {
  //       item_name: "Life Insurance",
  //       quantity: 1,
  //       unit_price: 3000,
  //       amount: 3000,
  //       currency: "USD",
  //     },
  //     {
  //       item_name: "Ovarc Fees",
  //       category: "",
  //       quantity: 1,
  //       unit_price: 600,
  //       amount: 600,
  //       currency: "USD",
  //     },
  //   ],
  // },
  // {
  //   id: "IND-229",
  //   description: "Health Insurance",
  //   status: "Pending",
  //   category: null,
  //   url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",

  //   total: {
  //     amount: 3600,
  //     currency: "USD",
  //   },
  //   issue_date: "2023-11-02",
  //   due_date: "2023-11-16",
  //   bill: {
  //     client_name: "Interlock - Egypt",
  //     address: "4140 Parker Rd. Allentown, Cairo ",
  //     registration_number: "PT5222410923",
  //   },
  //   items: [
  //     {
  //       item_name: "Life Insurance",
  //       quantity: 1,
  //       unit_price: 3000,
  //       amount: 3000,
  //       currency: "USD",
  //     },
  //     {
  //       item_name: "Ovarc Fees",
  //       category: "",
  //       quantity: 1,
  //       unit_price: 600,
  //       amount: 600,
  //       currency: "USD",
  //     },
  //   ],
  // },
];

export const PAYMENTS_LIST = [
  {
    id: 1,
    public_id: "PAY-133",
    status: "Pending Payment",
    value: 3600,
    confirmed_value: 4000,
    invoice_count: 1,
    created_at: "2023-11-02",
    transaction_id: "TXN094308OP",
    method: "Transfer",
    currency: "USD",
    company: {
      id: 1,
      name: "Company 1"
    },
    invoices:[
      {
        id: 1,
        status: "Pending Confirmation",
        total: 3227.74,
        description: "New Contract Initiation",
        due_date: "2024-09-10",
        created_at: "2024-08-11",
        type: "",
        public_id: "INV-222",
    }
    ],
    internal_bank_account: OVARC_BANK_ACCOUNT,
    balance: {
      current: 500,
      diff: -1000
    }
  },
  {
    id: 2,
    public_id: "PAY-139",
    status: "Processing",
    currency: "USD",
    value: 5000,
    confirmed_value: 4000,
    invoice_count: 1,
    created_at: "2023-11-02",
    transaction_id: "TXN094308OP",
    method: "Transfer",
    invoices:[
      {
        id: 1,
        status: "Pending Confirmation",
        total: 3227.74,
        description: "New Contract Initiation",
        due_date: "2024-09-10",
        created_at: "2024-08-11",
        type: "",
        public_id: "INV-222",
    }
  ],
  internal_bank_account: OVARC_BANK_ACCOUNT,
    balance: {
      current: 500,
      diff: -1000
    }
  },
];

export const PENDING_PAYMENTS_DASHBOARD = [
  {
    id: 1,
    status: "Pending Confirmation",
    transaction_id: "1",
    created_at: "2024-08-10",
    method: "Transfer",
    value: 2000,
  },
  {
    id: 2,
    status: "Pending Confirmation",
    transaction_id: "3",
    created_at: "2024-08-17",
    method: "Credit Card",
    value: 1100,
  },
  {
    id: 3,
    status: "Pending Confirmation",
    transaction_id: "2",
    created_at: "2024-08-12",
    method: "Crypto-Currency",
    value: 3100,
  },
  {
    id: 4,
    status: "Pending Confirmation",
    transaction_id: "2",
    created_at: "2024-08-15",
    method: "PayPal",
    value: 2509,
  }
]
// export const CATEGORIZED_INVOICES = [
//   {
//     id: "1",
//     category: "Assets",
//     status: "Pending",
//     invoices: [INVOICES_LIST[0], INVOICES_LIST[1], INVOICES_LIST[2]],
//     total: 9650,
//     currency: "USD",
//     due_date: "2023-11-16",
//     issue_date: "2023-11-02",
//   },
//   {
//     id: "2",
//     category: "Payroll",
//     status: "Pending",
//     invoices: [INVOICES_LIST[3], INVOICES_LIST[4]],
//     total: 60452,
//     currency: "USD",
//     due_date: "2023-11-16",
//     issue_date: "2023-11-02",
//   },
//   {
//     id: "3",
//     category: null,
//     status: null,
//     invoices: [INVOICES_LIST[5], INVOICES_LIST[6]],
//   },
// ];
