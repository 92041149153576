import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  DashboardContainer,
  DashboardHeader,
  PageSubtitle,
  PageTitle,
  UserName,
  DashboardContentContainer,
  CardsContainer,
  Grid,
} from "./styled";
import { useAppDispatch, useAppSelector } from "../../redux/reduxHooks";
import {
  removeUser,
  selectToken,
  selectUser,
  storeUserData,
} from "../../redux/slices/authSlice";
import { FontSizes, FontWeights } from "../../constants/styles";
import MultipleActionCard from "../../components/dashboard/MultipleActionCard";
import PaymentSection from "../../components/dashboard/PaymentSection";
import ContactUsBanner from "../../components/dashboard/ContactUsBanner";
import Companies from "../../components/dashboard/Companies";
import BannersCarousel from "../../components/dashboard/BannersCarousel";
import UpcomingPayrollCard from "../../components/dashboard/UpcomingPayrollCard";
import CurrentPayrollCard from "../../components/dashboard/CurrentPayrollCard";
import ComingSoonCard from "../../components/dashboard/ComingSoonCard";
import PieChartCard from "../../components/dashboard/PieChartCard";
import {
  AssetsCard,
  AssetsCardColors,
  PAYMENTS,
  PeopleCard,
  PeopleCardColors,
  PENDING_PAYMENTS_DASHBOARD,
} from "../../mockServer/MockData";
import { useGetUserData, useLogout } from "../../queries/auth";
import { useSnackbar } from "../../hooks/useSnackbar";
import { HOME_SCREEN } from "../../constants/routesNames";
import { SnackbarTypes } from "../../types/componentsProps";
import { useOnBoardingAndOffBoardingEmployeesDashboard } from "../../queries/people";
import { useUpcomingActionsDashboard } from "../../queries/companies";
import {
  StatusKey,
  UPCOMING_ACTIONS_ENUM,
  UpcomingActionsKey,
} from "../../types/dashboard";

const DashboardScreen: React.FC = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const user = useAppSelector(selectUser);
  const token = useAppSelector(selectToken);
  const dispatch = useAppDispatch();

  const [onboardingAndOffboardingData, setOnboardingAndOffboardingData] =
    useState<any>([
      {
        title: "No onboarding employees",
      },
      {
        title: "No off-boarding employees",
      },
    ]);
  const [upcomingActionsDashboardData, setUpcomingActionsDashboardData] =
    useState<any>([
      {
        title: "No upcoming actions",
      },
    ]);

  const { data: userDataRequest, error } = useGetUserData(!!token);
  const { data: onboardingAndOffboardingRequest } =
    useOnBoardingAndOffBoardingEmployeesDashboard();
  const { data: upcomingActionsDashboardRequest } =
    useUpcomingActionsDashboard();

  useEffect(() => {
    if (error) {

        showSnackbar({
          open: true,
          type: SnackbarTypes.ERROR,
          title: "Something went wrong",
          description:
            "An error occurred while fetching the data. Please try again later.",
        });
      
    }
  }, [error]);

  useEffect(() => {
    if (userDataRequest) {
      dispatch(storeUserData(userDataRequest));
    }
  }, [userDataRequest]);

  useEffect(() => {
    if (
      onboardingAndOffboardingRequest &&
      onboardingAndOffboardingRequest.statusCount &&
      Object.keys(onboardingAndOffboardingRequest?.statusCount).length > 0
    ) {
      let arrayOfOnBoardAndOffboard: any[] = (
        Object.keys(onboardingAndOffboardingRequest.statusCount) as StatusKey[]
      ).map((key) => {
        return {
          title: `${onboardingAndOffboardingRequest.statusCount[key]} ${key} Employee(s)`,
          btnText: "View",
          onClick: () => navigate(`/people/contracts`),
        };
      });
      if (arrayOfOnBoardAndOffboard.length > 0)
        setOnboardingAndOffboardingData(arrayOfOnBoardAndOffboard);
    }
  }, [onboardingAndOffboardingRequest]);

  const getUpcomingActionsData = (upcomingActionsDashboardRequest: any) => {
    let arrayOfUpcomingActions: any[] = [];
    if (
      upcomingActionsDashboardRequest?.upcomingActions &&
      Object.keys(upcomingActionsDashboardRequest?.upcomingActions).length > 0
    ) {
      (
        Object.keys(
          upcomingActionsDashboardRequest.upcomingActions
        ) as UpcomingActionsKey[]
      ).forEach((key) => {
        if (upcomingActionsDashboardRequest.upcomingActions[key] <= 0) {
          return;
        }

        let title: string = "";
        let btnText: string = "View";
        let onClickFn: () => void = () => {};
        switch (key) {
          case UPCOMING_ACTIONS_ENUM.CONTRACTS:
            title = `${upcomingActionsDashboardRequest.upcomingActions[key]} contract(s) will be auto renewed in 2 months`;
            btnText = "View";
            onClickFn = () => navigate(`/people/manage`);
            break;
          case UPCOMING_ACTIONS_ENUM.INVOICES:
            title = `${upcomingActionsDashboardRequest.upcomingActions[key]} invoice(s) due`;
            btnText = "View";
            onClickFn = () => navigate(`/payment`);
            break;
          case UPCOMING_ACTIONS_ENUM.PAYROLLS:
            title = `${upcomingActionsDashboardRequest.upcomingActions[key]} payroll(s) pending review`;
            btnText = "View";
            onClickFn = () => navigate(`/payroll`);
            break;
        }
        arrayOfUpcomingActions.push({
          title,
          btnText,
          onClick: onClickFn,
        });
      });
    }
    return arrayOfUpcomingActions;
  };

  useEffect(() => {
    if (upcomingActionsDashboardRequest) {
      const upcomingActionsData = getUpcomingActionsData(
        upcomingActionsDashboardRequest
      );
      if (upcomingActionsData.length > 0) {
        setUpcomingActionsDashboardData(upcomingActionsData);
      }
    }
  }, [upcomingActionsDashboardRequest]);

  return (
    <DashboardContainer>
      <DashboardHeader>
        <PageTitle size={FontSizes.SM} weight={FontWeights.BOLD}>
          Dashboard
        </PageTitle>
        <PageSubtitle size={FontSizes.TX_MD} weight={FontWeights.REGULAR}>
          Welcome back, <UserName>{user?.first_name}</UserName> !!
        </PageSubtitle>
      </DashboardHeader>
      <DashboardContentContainer>
        <CardsContainer>
          <CurrentPayrollCard />
          {/* <PieChartCard
            title="People"
            data={PeopleCard}
            colors={PeopleCardColors}
          /> */}
          <ComingSoonCard title="Assets" />
          {/* <PieChartCard
            title="Assets"
            data={AssetsCard}
            colors={AssetsCardColors}
          /> */}
          <UpcomingPayrollCard />
        </CardsContainer>
        <Grid hasBanners={true}>
          <MultipleActionCard
            title="Upcoming actions"
            actions={upcomingActionsDashboardData}
            style={{ gridArea: "upcoming" }}
          />
          <MultipleActionCard
            title="Onboarding & Off-boarding"
            actions={onboardingAndOffboardingData}
            style={{ gridArea: "onboarding" }}
          />
          <BannersCarousel />

          <PaymentSection style={{ gridArea: "payments" }} />
          <Companies />
          <ContactUsBanner />
        </Grid>
      </DashboardContentContainer>{" "}
    </DashboardContainer>
  );
};

export default DashboardScreen;
