import styled from "styled-components";
import { Colors, Spaces } from "../../../constants/styles";

export const LayoutContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  position: relative;
`;

export const AppContainer = styled.div<{ showSideBar: boolean }>`
  width: 100%;

  margin-left: ${(props) => (props.showSideBar ? "20%" : "0px")};

  @media (max-width: 1000px) {
    margin-left: 0;
  }
`;

export const MenuBurgerContainer = styled.div`
  display: none;
  cursor: pointer;
  margin-left: auto;
  margin-right: ${Spaces.spacing_4xl};

  @media (max-width: 1000px) {
    display: block;
  }
`;

export const AppHeader = styled.div`
  display: flex;
  background-color: ${Colors.bg_active};
  align-items: center;
  margin-inline-end: ${Spaces.spacing_md};
  min-height: 92px;
  width: 100%;
`;
