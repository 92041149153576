import { useRef, useState } from "react";
import Banner from "../../shared/Banner";

import ImageGallery from "react-image-gallery";
import { DashboardBanner } from "../../../types";
import "react-image-gallery/styles/css/image-gallery.css";
import {
  CarouselContainer,
  PaginationContainer,
  PaginationDot,
} from "./styled";
import { useAppSelector } from "../../../redux/reduxHooks";

const BannersCarousel = () => {
  const banners = useAppSelector((state) => state.appState.dashboardBanners);
  const [currentSlide, setCurrentSlide] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <CarouselContainer>
      <ImageGallery
        items={banners}
        renderItem={(item: DashboardBanner) => {
          return <Banner {...item} />;
        }}
        infinite
        showNav={false}
        showFullscreenButton={false}
        showThumbnails={false}
        showPlayButton={false}
        ref={ref}
        autoPlay={true}
        onBeforeSlide={(index: number) => setCurrentSlide(index)}
        slideInterval={3000}
      />
      {banners.length > 1 && (
        <PaginationContainer>
          {banners.map((_, index) => {
            return (
              <PaginationDot key={index} active={index === currentSlide} />
            );
          })}
        </PaginationContainer>
      )}
    </CarouselContainer>
  );
};

export default BannersCarousel;
