import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DropdownListOption } from "../../types/componentsProps";
import { Company } from "../../types/companies";

interface CompaniesState {
  createdCompanyId: string | null;
  userCompaniesOptions: DropdownListOption[];
  activeCompany: Company | null;
  currentInternalBankId: number;
  allCompanies: Company[];
}

const initialState: CompaniesState = {
  createdCompanyId: null,
  userCompaniesOptions: [],
  activeCompany: null,
  currentInternalBankId: 1,
  allCompanies: [],
};

const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    setCreatedCompanyId: (state, action: PayloadAction<string | null>) => {
      state.createdCompanyId = action.payload;
    },
    setUserCompaniesOptions: (
      state,
      action: PayloadAction<Company[]>
    ) => {
      const options = action.payload.map((company) => ({
        label: company.name,
        value: company.name,
      }));
      state.userCompaniesOptions = [{value: "All Companies", label: "All Companies"}, ...options];
      state.allCompanies = action.payload;
    },
    createCompaniesDropdownOptions: (
      state,
      action: PayloadAction<Company[]>
    ) => {
      const options=action.payload.map((company) => ({
        label: company.name,
        value: company.name,
      }));
      state.userCompaniesOptions = options
      state.activeCompany = action.payload[0];
    },
    setActiveCompany: (state, action: PayloadAction<Company | null>) => {
      state.activeCompany = action.payload;
    },
    setInternalBankId: (state, action: PayloadAction<number>) => {
      state.currentInternalBankId = action.payload;
    },
  },
});

export const {
  setCreatedCompanyId,
  setUserCompaniesOptions,
  createCompaniesDropdownOptions,
  setInternalBankId,
  setActiveCompany
} = companiesSlice.actions;

export default companiesSlice.reducer;
