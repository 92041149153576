import { Navigate, useParams } from "react-router-dom";
import {
  CardsColumn,
  CardsContainer,
  PayslipDetails,
  PayslipDetailsHeader,
  PayslipDetailsHeaderButtons,
  PayslipDetailsTitle,
} from "./styled";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import {
  Card,
  CardRow,
  CardRowTitle,
  CardRowValue,
  CardTitle,
} from "../../../shared/Card";
import Printer from "../../../../Images/icons/printer.svg";
import Download from "../../../../Images/icons/download.svg";
import Button from "../../../shared/Button";
import moment from "moment";
import { currencyFormatter } from "../../../../utilities/currencyFormatter";
import { useEffect, useState } from "react";
import {
  useDownloadPayslip,
  useGetPayslipDetails,
} from "../../../../queries/people";
import { SnackbarTypes } from "../../../../types/componentsProps";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { downloadFile, printPDF } from "../../../../utilities/file";

const EmployeePayslipDetails = () => {
  const { payslip_id } = useParams();
  const [downLoadStart, setDownloadStart] = useState<boolean>(false);
  const [isPrinting, setIsPrinting] = useState<boolean>(false);
  const { data: payslip, error } = useGetPayslipDetails(payslip_id);
  const {
    data: payslipFile,
    error: downloadPayslipError,
    isLoading,
  } = useDownloadPayslip({ payslipId: payslip_id, enabled: downLoadStart });

  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    if (payslipFile) {
      if (isPrinting) {
        printPDF(payslipFile);
        setIsPrinting(false);
      } else {
        downloadFile(
          payslipFile,
          `${payslip?.pay_period_start} to ${payslip?.pay_period_end} payslip`,
          "application/pdf"
        );
      }

      setDownloadStart(false);
    }
  }, [payslipFile]);

  useEffect(() => {
    if (error || downloadPayslipError) {
      showSnackbar({
        open: true,
        type: SnackbarTypes.ERROR,
        title: "Something went wrong",
        description: "Failed to fetch payslip details",
      });
    }
  }, [error, downloadPayslipError]);
  useEffect(() => {
    if (!payslip_id || error) {
      <Navigate to={"/404"} />;
    }
  }, [payslip_id, error]);

  return payslip ? (
    <PayslipDetails>
      <PayslipDetailsHeader>
        <PayslipDetailsTitle size={FontSizes.SM} weight={FontWeights.SEMIBOLD}>
          Payslip Details
        </PayslipDetailsTitle>
        <PayslipDetailsHeaderButtons>
          <Button
            text=""
            iconLeading={<img src={Printer} width={20} height={20} alt="" />}
            size={ButtonSizes.md}
            variant={ButtonVariants.secondary_color}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              setIsPrinting(true);
              setDownloadStart(true);
            }}
            loading={isLoading && isPrinting}
          />
          <Button
            text="Download"
            iconLeading={<img src={Download} width={20} height={20} alt="" />}
            size={ButtonSizes.md}
            variant={ButtonVariants.secondary_color}
            onClick={() => setDownloadStart(true)}
            loading={isLoading && !isPrinting}
          />
        </PayslipDetailsHeaderButtons>
      </PayslipDetailsHeader>
      <CardsContainer>
        <CardsColumn>
          {/* Employee information */}
          <Card>
            <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
              Employee information
            </CardTitle>
            <CardRow>
              <CardRowTitle
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                Employee name
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {payslip?.first_name} {payslip?.last_name}
              </CardRowValue>
            </CardRow>

            <CardRow>
              <CardRowTitle
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                Email
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {payslip?.email}
              </CardRowValue>
            </CardRow>

            <CardRow>
              <CardRowTitle
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                Job title
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {payslip?.job_title}
              </CardRowValue>
            </CardRow>
          </Card>

          {/* Earnings */}
          <Card>
            <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
              Earnings
            </CardTitle>

            <CardRow>
              <CardRowTitle
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                Basic salary
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {currencyFormatter(
                  parseFloat(payslip?.contract_salary_gross),
                  payslip?.currency
                )}
              </CardRowValue>
            </CardRow>

            <CardRow>
              <CardRowTitle
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                Bonuses
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {currencyFormatter(
                  parseFloat(payslip?.total_bonuses),
                  payslip?.currency
                )}
              </CardRowValue>
            </CardRow>

            <CardRow>
              <CardRowTitle
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                Allowance
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {currencyFormatter(0, payslip?.currency)}
              </CardRowValue>
            </CardRow>
          </Card>

          {/* Net Pay */}
          <Card>
            <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
              Net Pay
            </CardTitle>
            <CardRow>
              <CardRowTitle
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                Total earnings
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {currencyFormatter(
                  parseFloat(payslip?.salary_gross),
                  payslip?.currency
                )}
              </CardRowValue>
            </CardRow>

            <CardRow>
              <CardRowTitle
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                Total deductions
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {currencyFormatter(
                  parseFloat(payslip?.taxes) + parseFloat(payslip?.insurance),
                  payslip?.currency
                )}
              </CardRowValue>
            </CardRow>

            <CardRow>
              <CardRowTitle
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                Net pay
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {currencyFormatter(
                  parseFloat(payslip?.salary_net),
                  payslip?.currency
                )}
              </CardRowValue>
            </CardRow>
          </Card>
        </CardsColumn>

        <CardsColumn>
          {/* Pay Period & status */}
          <Card>
            <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
              Pay Period & status
            </CardTitle>
            <CardRow>
              <CardRowTitle
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                Start and End Dates
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {moment(payslip?.pay_period_start).format("DD MMM, YYYY")} -{" "}
                {moment(payslip?.pay_period_end).format("DD MMM, YYYY")}
              </CardRowValue>
            </CardRow>
            <CardRow>
              <CardRowTitle
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                Date of payment
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {moment(payslip?.payment_date).format("DD MMM, YYYY")}
              </CardRowValue>
            </CardRow>
          </Card>

          {/* Deductions */}
          <Card>
            <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
              Deductions
            </CardTitle>

            <CardRow>
              <CardRowTitle
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                Health Insurance
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {currencyFormatter(0, payslip?.currency)}
              </CardRowValue>
            </CardRow>

            <CardRow>
              <CardRowTitle
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                Social Insurance
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {currencyFormatter(
                  parseFloat(payslip?.insurance),
                  payslip?.currency
                )}
              </CardRowValue>
            </CardRow>

            <CardRow>
              <CardRowTitle
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                Tax Deduction
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {currencyFormatter(
                  parseFloat(payslip?.taxes),
                  payslip?.currency
                )}
              </CardRowValue>
            </CardRow>
          </Card>
        </CardsColumn>
      </CardsContainer>
    </PayslipDetails>
  ) : null;
};

export default EmployeePayslipDetails;
