import { useNavigate, useParams } from "react-router-dom";
import {
  Divider,
  InvoiceDetailsCards,
  InvoiceDetailsContainer,
  InvoiceDetailsHeader,
  InvoiceDetailsHeaderButtons,
  InvoiceDetailsSubtitle,
  InvoiceDetailsTitle,
  ItemsTableContainer,
  TableTextBold,
  TableTextFaded,
  TotalText,
  TotalTextContainer,
} from "./styled";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";
import Printer from "../../../Images/icons/printer.svg";
import Download from "../../../Images/icons/download.svg";
import Button from "../../shared/Button";
import { useAppDispatch } from "../../../redux/reduxHooks";
import {
  Card,
  CardRow,
  CardRowTitle,
  CardRowValue,
  CardTitle,
} from "../../shared/Card";
import moment from "moment";
import Badge from "../../shared/Badge";
import { INVOICE_STATUS_BADGE_COLOR } from "../../../types/payments";
import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from "../../shared/Table/styled";
import { currencyFormatter } from "../../../utilities/currencyFormatter";
import {
  selectSingleInvoice,
  setTotalPayment,
} from "../../../redux/slices/paymentSlice";
import {
  useDownloadInvoice,
  useGetInvoiceDetails,
} from "../../../queries/payment";
import { useEffect, useState } from "react";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { SnackbarTypes } from "../../../types/componentsProps";
import { downloadFile, printPDF } from "../../../utilities/file";

const InvoiceDetails = () => {
  const { invoice_id } = useParams();
  const [startDownload, setStartDownload] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const { data: invoice, error } = useGetInvoiceDetails(invoice_id);
  const {
    data: invoiceBlobFile,
    error: invoiceFileError,
    isLoading,
  } = useDownloadInvoice({
    invoiceId: invoice_id,
    enabled: startDownload,
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { showSnackbar } = useSnackbar();
  useEffect(() => {
    if (error || invoiceFileError) {
      const title = error
        ? "Something went wrong while fetching invoice details"
        : "Something went wrong while fetching invoice file";

      showSnackbar({
        type: SnackbarTypes.ERROR,
        open: true,
        title,
        description: "Please try again later.",
      });

      error && navigate("/payment");
    }
  }, [error, invoiceFileError]);

  const renderRows = () => {
    return invoice?.line_items.map((item, index) => {
      return (
        <>
          <TableRow>
            <TableCell
              style={{
                width: "70%",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <TableTextBold
                  size={FontSizes.TX_SM}
                  weight={FontWeights.MEDIUM}
                >
                  {item.description}
                </TableTextBold>
              </div>
            </TableCell>
            <TableCell>
              <TableTextFaded
                size={FontSizes.TX_SM}
                weight={FontWeights.MEDIUM}
              >
                {item.quantity}
              </TableTextFaded>
            </TableCell>
            <TableCell>
              <TableTextFaded
                size={FontSizes.TX_SM}
                weight={FontWeights.MEDIUM}
              >
                {currencyFormatter(item.unit_price, invoice.currency)}
              </TableTextFaded>
            </TableCell>
            <TableCell>
              <TableTextFaded
                size={FontSizes.TX_SM}
                weight={FontWeights.MEDIUM}
              >
                {currencyFormatter(
                  item.quantity * item.unit_price,
                  invoice.currency
                )}
              </TableTextFaded>
            </TableCell>
          </TableRow>
          {index !== invoice.line_items.length - 1 && <Divider />}
        </>
      );
    });
  };

  useEffect(() => {
    if (invoiceBlobFile) {
      if (isPrinting) {
        printPDF(invoiceBlobFile);
        setIsPrinting(false);
      } else {
        downloadFile(invoiceBlobFile, `Invoice #${invoice?.public_id}`,'application/pdf');
      }

      setStartDownload(false);
    }
  }, [invoiceBlobFile]);

  return invoice ? (
    <InvoiceDetailsContainer>
      <InvoiceDetailsHeader>
        <div>
          <InvoiceDetailsTitle
            size={FontSizes.SM}
            weight={FontWeights.SEMIBOLD}
          >
            {invoice.public_id}
          </InvoiceDetailsTitle>
          <InvoiceDetailsSubtitle
            size={FontSizes.TX_MD}
            weight={FontWeights.REGULAR}
          >
            {invoice.description}
          </InvoiceDetailsSubtitle>
        </div>
        <InvoiceDetailsHeaderButtons>
          <Button
            text=""
            iconLeading={<img src={Printer} width={20} height={20} alt="" />}
            size={ButtonSizes.md}
            variant={ButtonVariants.secondary_color}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              setIsPrinting(true);
              setStartDownload(true);
            }}
            loading={isLoading && isPrinting}
          />
          <Button
            text="Download"
            iconLeading={<img src={Download} width={20} height={20} alt="" />}
            size={ButtonSizes.md}
            variant={ButtonVariants.secondary_color}
            onClick={() => setStartDownload(true)}
            loading={isLoading && !isPrinting}
          />
          {invoice.status === "Initiated" || invoice.status === "Pending" ? (
            <Button
              text="Pay Invoice"
              size={ButtonSizes.md}
              variant={ButtonVariants.primary}
              onClick={() => {
                dispatch(selectSingleInvoice(invoice));
                dispatch(setTotalPayment(parseFloat(invoice.total)));
                navigate("/payment/pay-invoices");
              }}
            />
          ) : null}
        </InvoiceDetailsHeaderButtons>
      </InvoiceDetailsHeader>
      <InvoiceDetailsCards>
        <Card>
          <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            Bill To
          </CardTitle>
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Client Name
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {invoice.company.name}
            </CardRowValue>
          </CardRow>
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Address
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {invoice.company.billing_address ?? "-"}
            </CardRowValue>
          </CardRow>
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Registration Number
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {invoice.company.registration_number}
            </CardRowValue>
          </CardRow>
        </Card>
        <Card>
          <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            Invoice Details
          </CardTitle>
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Issue Date
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {moment(invoice.created_at).format("DD MMM, YYYY")}
            </CardRowValue>
          </CardRow>
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Due Date
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {moment(invoice.due_date).format("DD MMM, YYYY")}
            </CardRowValue>
          </CardRow>
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Status
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              <Badge
                text={invoice.status}
                dot
                color={INVOICE_STATUS_BADGE_COLOR[invoice.status]}
              />
            </CardRowValue>
          </CardRow>
        </Card>
      </InvoiceDetailsCards>
      <ItemsTableContainer>
        <Table>
          <TableHeader>
            <TableCell
              style={{
                width: "70%",
              }}
            >
              <TableTextFaded
                size={FontSizes.TX_XS}
                weight={FontWeights.MEDIUM}
              >
                Item
              </TableTextFaded>
            </TableCell>

            <TableCell>
              {" "}
              <TableTextFaded
                size={FontSizes.TX_XS}
                weight={FontWeights.MEDIUM}
              >
                QTY
              </TableTextFaded>
            </TableCell>
            <TableCell>
              {" "}
              <TableTextFaded
                size={FontSizes.TX_XS}
                weight={FontWeights.MEDIUM}
              >
                Unit Price
              </TableTextFaded>
            </TableCell>
            <TableCell>
              {" "}
              <TableTextFaded
                size={FontSizes.TX_XS}
                weight={FontWeights.MEDIUM}
              >
                Amount
              </TableTextFaded>
            </TableCell>
          </TableHeader>
          <Divider />
          {renderRows()}
        </Table>
      </ItemsTableContainer>
      <TotalTextContainer>
        <TotalText size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Total
        </TotalText>
        <TotalText size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          {currencyFormatter(parseInt(invoice.total), invoice.currency)}
        </TotalText>
      </TotalTextContainer>
    </InvoiceDetailsContainer>
  ) : null;
};

export default InvoiceDetails;
